import { ref } from 'vue'

export const queryID = ref<string>('')
export const queryToken = ref<string>('')
export const isInjected = ref<boolean>(false)

export function setUrlQueryParams (injected: boolean, id: string, onTokenInjected: () => void, token = '') {
    isInjected.value = injected
    queryID.value = id
    queryToken.value = token
    if (injected) {
        addMessageListener(onTokenInjected)
        window.parent.postMessage({
            type: 'ready',
            initiator: 'VoicenterCallHistory'
        }, '*')
    } else if (token) {
        onTokenInjected()
    } else {
        onTokenInjected()
    }
}

export function getUrlQueryParams () {
    return {
        queryID: queryID.value,
        queryToken: queryToken.value
    }
}

export function addMessageListener (callback: () => void) {
    window.addEventListener('message', (event) => {
        if (event.data.type === 'token') {
            queryToken.value = event.data.data
            callback()
        }
    })
}
