import { createI18n, I18n } from 'vue-i18n'
import { flatten } from '@/helpers/object.helper'
import en from '@/locales/en.json'
import he from '@/locales/he.json'

const messages: Record<string, Record<string, string>> = {
    en: flatten(en),
    he: flatten(he),
}

export const FALLBACK_LOCALE = import.meta.env.VITE_APP_FALLBACK_LOCALE ?? 'en'


export function createI18nCustom (): I18n<Record<string, Record<string, string>>, Record<string, unknown>, Record<string, unknown>, string, false> {

    return createI18n({
        legacy: false,
        locale: 'en',
        availableLocales: [ 'en', 'he' ],
        globalInjection: true,
        fallbackLocale: FALLBACK_LOCALE,
        messages,
    })

}

export default createI18nCustom()
