import { computed, ref } from 'vue'
import { JWTPayloadData } from '@/types/api'

export const jwtPayloadData = ref<JWTPayloadData | undefined>(undefined)

export const isUserAdmin = computed(() => {
    return jwtPayloadData.value?.IsAdmin
})

export function setJWTPayloadData (jwtPayload?: JWTPayloadData) {
    jwtPayloadData.value = jwtPayload
}
export function getTokenFromResponseData () {
    return jwtPayloadData.value?.jwt || ''
}
