<template>
    <div class="w-full">
        <div class="w-full flex gap-1">
            <VcSelect
                v-model="campaignIdModel"
                v-bind="parameterData"
                :disabled="disabled"
                :config="campaignSelectorConfig"
                filterable
                :options="campaignList"
            />
            <VcDatetimeRangePicker
                v-model="campaignDateStringModel"
                v-bind="parameterData"
                :disabled="disabled"
                class="w-full"
                auto-apply
                enable-time-picker
                enable-seconds
            />
        </div>
        <div class="w-full flex gap-1">
            <div class="flex-grow">
                <VcFormItem
                    label="Target"
                    label-position="top"
                >
                    <VcInput
                        v-model="campaignTargetModel"
                        :disabled="disabled"
                    />
                </VcFormItem>
            </div>
            <div class="flex-grow">
                <VcFormItem
                    label="Caller Name"
                    label-position="top"
                >
                    <VcInput
                        v-model="campaignCallerNameModel"
                        :disabled="disabled"
                    />
                </VcFormItem>
            </div>
            <div class="flex-grow">
                <VcFormItem
                    label="Priority"
                    label-position="top"
                >
                    <template #label-action>
                        <VcButtonIcon
                            size="small"
                            icon="vc-icon-clear"
                            type="plain"
                            @click="clearCampaignPriority"
                        />
                    </template>

                    <VcInputNumber
                        v-model="campaignPriorityModel"
                        :disabled="disabled"
                        :min-value="1"
                        :max-value="29999"
                        full-width
                    />
                </VcFormItem>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, ref, WritableComputedRef } from 'vue'
import {
    VcButtonIcon,
    VcDatetimeRangePicker,
    VcFormItem,
    VcInput,
    VcInputNumber,
    VcSelect
} from '@voicenter-team/voicenter-ui-plus'
import { ExtendParameterDataType, TBaseParameterData, useDynamicComponent } from '@voicenter-team/form-renderer'
import { ObjectAnyType } from '@/types/types'

/* Const */
const DEFAULT_CAMPAIGN_DATE_FORMAT = 'yyyy-MM-dd HH-mm-ss' as const
const CAMPAIGN_ID_MODEL_KEY = 'campaignID' as const
const CAMPAIGN_DATE_MODEL_KEY = 'campaignDate' as const
const CAMPAIGN_TARGET_MODEL_KEY = 'target' as const
const CAMPAIGN_CALLER_NAME_MODEL_KEY = 'callerName' as const
const CAMPAIGN_PRIORITY_MODEL_KEY = 'priority' as const
const HIERARCHY_TYPES = {
    CURRENT_WITH_CHILDREN: 1,
    CURRENT: 2,
    FROM_TOP_ACCOUNT: 3
} as const

/* Types */
type TCampSelectData = {
    [CAMPAIGN_ID_MODEL_KEY]?: number
    [CAMPAIGN_DATE_MODEL_KEY]?: string
    [CAMPAIGN_TARGET_MODEL_KEY]?: string
    [CAMPAIGN_CALLER_NAME_MODEL_KEY]?: string
    [CAMPAIGN_PRIORITY_MODEL_KEY]?: number
}

interface ICampSelectParameterData extends TBaseParameterData {
    defaultValue: TCampSelectData
    hierarchyType: typeof HIERARCHY_TYPES[keyof typeof HIERARCHY_TYPES]
    campaignDateFormat: string
    disabled?: boolean
    campaignIdFilterList: Array<number>
}

type TCampSelectParameter = ExtendParameterDataType<'CustomParameter', ICampSelectParameterData>

/* Props */
type Props = {
    modelValue: object
    parameter: TCampSelectParameter
    disabled: boolean
}
const props = defineProps<Props>()

/* Emit */
const emit = defineEmits<{
    (e: 'update:modelValue', payload: Props['modelValue']): void
}>()

/* Data */
const campaignList = ref<Array<ObjectAnyType>>([])
const parameterData: ICampSelectParameterData = {
    ...props.parameter.parameterData,
    defaultValue:
        props.parameter.parameterData.defaultValue
        && typeof props.parameter.parameterData.defaultValue === 'object'
        && !Array.isArray(props.parameter.parameterData.defaultValue)
            ? props.parameter.parameterData.defaultValue
            : {},
    campaignIdFilterList: props.parameter.parameterData.campaignIdFilterList ?? [],
    hierarchyType: props.parameter.parameterData.hierarchyType ?? HIERARCHY_TYPES.CURRENT_WITH_CHILDREN,
    campaignDateFormat: props.parameter.parameterData.campaignDateFormat ?? DEFAULT_CAMPAIGN_DATE_FORMAT
}
const campaignSelectorConfig = {
    labelKey: 'CampainName',
    valueKey: 'CampaignID'
} as const
// TODO: need to fix it in form-renderer
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const model = useDynamicComponent(props, parameterData, emit) as WritableComputedRef<TCampSelectData>

/* Computed */
const disabled = computed(() => {
    return props.disabled || parameterData.disabled
})
const campaignIdModel = computed({
    get () {
        return Number(model.value[CAMPAIGN_ID_MODEL_KEY])
    },
    set (val) {
        model.value = {
            ...model.value,
            [CAMPAIGN_ID_MODEL_KEY]: Number(val)
        }
    }
})
const campaignDate = computed({
    get () {
        return model.value[CAMPAIGN_DATE_MODEL_KEY]
    },
    set (val) {
        model.value = {
            ...model.value,
            [CAMPAIGN_DATE_MODEL_KEY]: `${val}`
        }
    }
}) as WritableComputedRef<string | number>
const campaignTargetModel = computed({
    get () {
        return model.value[CAMPAIGN_TARGET_MODEL_KEY]
    },
    set (val) {
        model.value = {
            ...model.value,
            [CAMPAIGN_TARGET_MODEL_KEY]: val
        }
    }
})
const campaignCallerNameModel = computed({
    get () {
        return model.value[CAMPAIGN_CALLER_NAME_MODEL_KEY]
    },
    set (val) {
        model.value = {
            ...model.value,
            [CAMPAIGN_CALLER_NAME_MODEL_KEY]: val
        }
    }
})
const campaignPriorityModel = computed({
    get () {
        return model.value[CAMPAIGN_PRIORITY_MODEL_KEY]
    },
    set (val) {
        model.value = {
            ...model.value,
            [CAMPAIGN_PRIORITY_MODEL_KEY]: val
        }
    }
})
const campaignDateStringModel = computed({
    get () {
        const date = campaignDate.value

        return parseCampaignDate(date)
    },
    set (val) {
        campaignDate.value = millisecondsToSeconds(new Date(val).getTime())
    }
})


/* Methods */
function millisecondsToSeconds (value: number)  {
    const stringValue = value.toString()

    if (stringValue.length === 13) {
        return Math.floor(value / 1000)
    } else {
        return value
    }
}
function parseCampaignDate (date: string | number | undefined) {
    if (typeof date === 'string') {
        return date
    } else if (date) {
        return new Date(maybeSecondsToMilliseconds(date)).toISOString()
    } else {
        return new Date().toISOString()
    }
}
function maybeSecondsToMilliseconds (value: number) {
    const stringValue = value.toString()

    if (stringValue.length === 13) {
        return value
    } else if (stringValue.length === 10) {
        return value * 1000
    } else {
        return value
    }
}


function clearCampaignPriority () {
    campaignPriorityModel.value = undefined
}

const loadCampaignList = async () => {
    campaignList.value = [
        {
            AccountID: 10012,
            CampaignID: 14452,
            CampainName: 'Second Test Campaign',
            CampaignType: 3,
            CampaignStatus: 1,
            CampainCode: 'JFlq3t1vVfyCNYgWV6kycGA1oBQXANfm'
        },
        {
            AccountID: 10012,
            CampaignID: 14454,
            CampainName: 'Campaign Name',
            CampaignType: 3,
            CampaignStatus: 2,
            CampainCode: 'LLlRsYGKrT8KPohg7iGABi0zKHJKJr4N'
        },
        {
            AccountID: 10012,
            CampaignID: 14459,
            CampainName: 'Agents Auto Dialer Demo',
            CampaignType: 5,
            CampaignStatus: 1,
            CampainCode: 'NX7xG3Rd3mC0mCWZUEasLkWMEVHFFows'
        },
        {
            AccountID: 10012,
            CampaignID: 15348,
            CampainName: 'DialerIVRDev',
            CampaignType: 3,
            CampaignStatus: 2,
            CampainCode: '68K0YGg5RjFAWBdMl2bvijFKzpp9Uvm5'
        },
        {
            AccountID: 10012,
            CampaignID: 15349,
            CampainName: 'DialerAgentDev',
            CampaignType: 5,
            CampaignStatus: 2,
            CampainCode: 'TNTrcVoCTK1LnFab7fCL230TMADOLUXZ'
        },
        {
            AccountID: 10012,
            CampaignID: 14431,
            CampainName: 'קמפיין חכם בדיקה',
            CampaignType: 3,
            CampaignStatus: 1,
            CampainCode: 'vT4KFPAWfPguAC5qI4NbhNmYm4ICcjkM'
        },
        {
            AccountID: 10012,
            CampaignID: 17849,
            CampainName: 'Ivan Cumpaign',
            CampaignType: 5,
            CampaignStatus: 2,
            CampainCode: 'XJr1MELNRZZQ2XUC53aojUpRPf4ZbgcF'
        },
        {
            AccountID: 10012,
            CampaignID: 17856,
            CampainName: 'Queue Testing CumPaign',
            CampaignType: 5,
            CampaignStatus: 2,
            CampainCode: '4nowVDzxTJlN1v96w3deMXNYPd4nx0E2'
        },
        {
            AccountID: 10012,
            CampaignID: 17857,
            CampainName: 'Queue Testing CumPaign Agents',
            CampaignType: 5,
            CampaignStatus: 2,
            CampainCode: 'Gw5AZEe6SFAnfVESYlVr5HeuKg4UiJdy'
        }
    ].sort((a,b) => a.CampainName.localeCompare(b.CampainName))
    // const filter: ObjectAnyType = {
    //     CampaignStatusIDList: [ 1 ],
    //     CampaignIDList: parameterData.campaignIdFilterList
    // }
    //
    // return CampaignEntity
    //     .list(filter)
    //     .then(res => {
    //         campaignList.value = res
    //     })
}

/* Created */
loadCampaignList()
</script>
