import { ApiService } from '@/services/ApiService'

const DOMAIN = import.meta.env.MODE === 'development'
    ? 'betacpanel.voicenter.com'
    : window.location.hostname

class ContentsService {
    public BASE_URL = 'https://api-manager.voicenter.co'
    public API_VER = '/api-manager-v1/'
    private service

    constructor () {
        this.service = new ApiService(this.BASE_URL)
    }

    public async contentsTagsList (LanguageID?: number, domain?: string) {
        const url = this.API_VER + 'Content/TagList/'
        const data = {
            Domain: domain || DOMAIN,
            LanguageID,
            SectionList: [
                'callHistoryView'
            ]
        }

        return this.service.post(url, data)
    }
}

export default new ContentsService()
