import { App } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VChart from 'vue-echarts/csp'
import useEChartComponents from '@/composables/useEChartComponents.ts'

export default {
    install: (app: App) => {
        app.component('VChart', VChart)
        useEChartComponents()
    }
}
