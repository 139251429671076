<template>
    <div class="w-full call-ai-chart-block">
        <div class="ai-lines-chart mb-4">
            <EmotionalLinesChart
                ref="lineChartRef"
                @click="onChartClick"
            />
        </div>
        <div class="px-4">
            <CallHistoryAiSound
                ref="soundRef"
                @update:currentTime="onSoundTimeUpdate"
            />
        </div>
        <div class="py-6 px-6 xl:px-8">
            <ChartActivityCard
                :message="currentMessage"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'
import EmotionalLinesChart from '@/components/Chart/EmotionalLinesChart.vue'
import CallHistoryAiSound from '@/components/Common/CallHistoryAiSound.vue'
import ChartActivityCard from '@/components/Card/ChartActivityCard.vue'
import { TMessageWithEmotionItem } from '@/types/types'
import { getMessageByTime } from '@/composables/useAiTabData.ts'


const emit = defineEmits<{
    (e: 'update:time', time: number): void
    (e: 'click:time', time: number): void
}>()

/* Data */
const soundRef = ref<typeof CallHistoryAiSound>()
const lineChartRef = ref<typeof EmotionalLinesChart>()
const currentMessage = ref<TMessageWithEmotionItem | undefined>(undefined)

/* Methods */
function scrollTimeLine (time: number) {
    lineChartRef.value?.setTimePointer(time)
}
// function filterPolarChartMessages (time: number) {
//     polarChartRef.value?.setTime(time)
// }
function onChartClick (data: { time: number }) {
    // const time = Math.max(data.time - 0.3, 0)
    soundRef.value?.setTime(data.time, true)
    setMessageByTime(data.time)
    emit('update:time', data.time)
}
function onSoundTimeUpdate (time: number) {
    scrollTimeLine(time)
    setMessageByTime(time)
    // filterPolarChartMessages(time)
    emit('update:time', time)
}
function setMessageByTime (time: number) {
    currentMessage.value = getMessageByTime(time) || currentMessage.value
}
function setTime (time: number) {
    soundRef.value?.setTime(time)
}

function initData () {
    nextTick(() => {
        currentMessage.value = getMessageByTime(0)
    })
}
function highlightEmotionsByUser (data: { label: string, type: string, user: number }) {
    lineChartRef.value?.highlight(data)
}

initData()

defineExpose({
    setTime,
    initData,
    highlightEmotionsByUser
})
</script>
