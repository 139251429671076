import { z } from 'zod'

const stringToJSONSchema = z.string()
    .transform( ( str, ctx ): z.infer<ReturnType<typeof JSON.parse>> => {
        try {
            return JSON.parse( str )
        } catch ( e ) {
            ctx.addIssue( {
                code: 'custom',
                message: 'Invalid JSON'
            } )
            return z.NEVER
        }
    } )

export const env = z.object({

    VITE_APP_FALLBACK_LOCALE: z.enum([ 'he', 'en' ]).or(z.undefined()),
    VITE_APP_LOCAL_DIRECTION: z.enum([ 'ltr', 'rtl' ]).or(z.undefined()),
    VITE_APP_REDIRECT_TO_ERROR_PAGE: z.string().or(z.undefined()),
    VITE_APP_ELASTIC_PORT: z.string().or(z.undefined()),
    VITE_APP_ELASTIC_IP: z.string().or(z.undefined()),
    VITE_APP_ELASTIC_TOKEN: z.string().or(z.undefined()),
    VITE_LOCAL_PDF_URL: z.string().url().or(z.undefined()),
    VITE_APP_PDF_FOOTER_PHONE: z.string().or(z.undefined()),
    VITE_APP_PDF_FOOTER_EMAIL: z.string().or(z.undefined()),
    VITE_APP_PDF_FOOTER_SITE_NAME: z.string().or(z.undefined()),
    VITE_APP_PDF_FOOTER_SITE_URL: z.string().url().or(z.undefined()),
    VITE_APP_API_MANAGER_BASE_URL: z.string().url().or(z.undefined()),

    VITE_ROUTE_REDIRECT_ERROR: z.string().or(z.undefined()),
    VITE_APP_LAYOUT_NAME: z.string().or(z.undefined()),
    VITE_APP_TEST_LOCAL_DATA: z.string().or(z.undefined()),
    VITE_APP_LAYOUT_FAVICON: z.string().or(z.undefined()),
    VITE_PDF_LOGO_IMAGE: z.string().or(z.undefined()),

    VITE_APP_LOADER_NAME: z.enum([ 'VoiceLoader', 'SimpleLoader', 'OfficeringLoader' ]).or(z.undefined()),

    VITE_APP_CONFIGS: stringToJSONSchema.pipe(z.object({
        id: z.string(),
        redirect_uri: z.string()
    })).or(z.undefined())
})

export default env.parse(import.meta.env)
