import { computed, ref } from 'vue'
import { IRelatedCallItem } from '@/types/api'

export const relatedCallsData = ref<Array<IRelatedCallItem>>([])

export const hasRelatedCalls = computed(() => {
    return !!relatedCallsData?.value?.length
})

export function setRelatedCallsData (data: Array<IRelatedCallItem>) {
    relatedCallsData.value = [ ...data ]
}
