import { CdrData, IRelatedCallItem } from '@/types/api'
import { CDR_TYPE_SET } from '@/enums/cdrTypes.enum.ts'

export const INCOMING_CDR_TYPE_LIST: Array<number> = [
    CDR_TYPE_SET.INCOMING,
    CDR_TYPE_SET.QUEUE,
    CDR_TYPE_SET.CLICK_2_CALL_LEG_1,
    CDR_TYPE_SET.CLICK_2_QUEUE_INCOMING
]
export const OUTGOING_CDR_TYPE_LIST: Array<number> = [
    CDR_TYPE_SET.EXTENSION_OUTGOING
]

export const isCallIncoming = (call: CdrData | IRelatedCallItem) => {
    return INCOMING_CDR_TYPE_LIST.includes(call.cdr_type || -1)
}

export const isCallOutgoing = (call: CdrData | IRelatedCallItem) => {
    return OUTGOING_CDR_TYPE_LIST.includes(call.cdr_type || -1)
}

export function isExtensionUser  (number: string = '') {
    // 8 characters long and has letters
    const regex = /^(?=.*[^0-9]).{8}$/

    return regex.test(number)
}

export function getCallerName (call: CdrData | IRelatedCallItem) {
    if (isExtensionUser(call.caller_no)) {
        return call.oUT_extension_name || '---'
    } else {
        return call.caller_no || '---'
    }
}

export function getTargetName (call: CdrData | IRelatedCallItem) {
    if (isExtensionUser(call.target_no)) {
        return call.iNC_extension_name || '---'
    } else {
        return call.target_no || '---'
    }
}

// Get the number of the client
export const getCallClientNameOrNumber = (call: CdrData | IRelatedCallItem) => {
    if (isCallIncoming(call)) {
        return getCallerName(call)
    } else  {
        return getTargetName(call)
    }
}

