<template>
    <div
        :class="{
            'is--reverse': isReverse,
            'is--highlight': isHighlighted,
            'is--active': activeMessage === message.sentence_id
        }"
        class="transcription-message relative flex flex-col w-full"
    >
        <div class="transcription-message__details relative flex items-center gap-1 text-xs font-medium mb-1">
            <div class="font-semibold capitalize">
                {{ getCallerDataByMessage(message) }}
            </div>
            <span
                class="block rounded-full bg-inactive-elements"
                style="width: 4px; height: 4px"
            />
            <div>
                {{ timeMessage }}
            </div>
            <span
                class="block rounded-full bg-inactive-elements"
                style="width: 4px; height: 4px"
            />
            <div class="capitalize">
                {{ message.emotion.emotion || '--' }}
            </div>
            <span
                class="block rounded-full emotional-circle"
                :style="{
                    width: '6px',
                    height: '6px',
                    '--color': getEmotionColor(message?.emotion?.emotion)
                }"
            />
        </div>
        <div
            class="transcription-message__box flex max-w-[90%] cursor-pointer"
            style="width: max(68%, 540px)"
            @click.stop="setSoundTime"
        >
            <div
                class="transcription-message__content px-2 py-1"
            >
                {{ message.text }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { TMessageItem, TMessageWithEmotionItem } from '@/types/types'
import { getParsedMessageTimeFromSeconds } from '@/helpers/dateTime.helper'
import { getCallerDataByMessage, getEmotionColor } from '@/composables/useAiTabData'

const props = defineProps<{
    message: TMessageWithEmotionItem
    isReverse?: boolean
    searchData?: string
    activeMessage?: number
}>()
const emit = defineEmits<{(e: 'click:message', msg: TMessageItem): void}>()

const timeMessage = computed(() => {
    const from = getParsedMessageTimeFromSeconds(props.message.startTime)
    const to = getParsedMessageTimeFromSeconds(props.message.endTime)
    return `${from}-${to}`
})
const isHighlighted = computed(() => {
    if (!props.searchData) {
        return false
    }
    return props.message.text.toLocaleLowerCase().includes(props.searchData.toLocaleLowerCase().trim())
})

function setSoundTime () {
    emit('click:message', props.message)
}
</script>

<style lang="scss">
.transcription-message {
    &__content {
        @apply rounded-xl rounded-tl-none;
        color: var(--message-agent-text-color, #2A2C36);
        background-color: var(--message-agent-bg-color, #EDF4FF);
    }

    .emotional-circle {
        background-color: var(--color, var(--inactive-elements));
    }

    &.is--reverse {
        @apply items-end;

        .transcription-message__box {
            @apply flex-row-reverse;
        }

        .transcription-message__content {
            @apply rounded-xl rounded-tr-none;
            color: var(--message-client-text-color, #fff);
            background-color: var(--message-client-bg-color, #2675FF);
        }
    }
    &.is--highlight {
        .transcription-message__content {
            box-shadow:
                0 2px 4px 0 rgba(0, 0, 0, 0.08),
                0 4px 6px 0 rgba(38, 117, 255, 0.12),
                0 6px 9px 0 rgba(38, 117, 255, 0.12)
        ;
        }
    }
    &.is--active {
        .transcription-message__content {
            border: 1px solid var(--message-active-border-color, #2693FF);
            box-shadow:
                0 2px 4px 0 rgba(1, 17, 112, 0.08),
                0 6px 8px 0 rgba(13, 43, 94, 0.12),
                0 8px 12px 0 rgba(4, 40, 103, 0.12)
        ;
        }
    }
}
</style>
