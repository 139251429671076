import { isWidgetState, startGlobalLoading, stopGlobalLoadingByDelay } from '@/services/GlobalState.ts'
import { getUrlQueryParams } from '@/composables/useQueryData.ts'
import ApiManager from '@/services/API/ApiManager.ts'
import { setJWTPayloadData } from '@/composables/useJWTPayloadData.ts'
import { setEncryptedData } from '@/composables/useEncryptedData.ts'
import { setRecordingData } from '@/composables/useCallRecording.ts'
import { CallAiOnlyHistoryData, CallHistoryData } from '@/types/api'
import TagsWorker from '@/services/TagsWorker.ts'
import { setCdrData } from '@/composables/useCDRData.ts'
import { setAiData } from '@/composables/useAIData.ts'
import { setRelatedCallsData } from '@/composables/useRelatedCallsData.ts'

function stopLoading (time = 300) {
    stopGlobalLoadingByDelay(time)
}
function startLoading () {
    startGlobalLoading()
}

export function onError () {
    if (import.meta.env.MODE === 'development') {
        return
    }
    if (import.meta.env.VITE_APP_LAYOUT_NAME) {
        const url = new URL('/ai/error')
        window.history.replaceState({}, '', url)
        return
    }
    console.log('document.referrer', document.referrer)
    console.log('window.origin', window.origin)

    if (document.referrer.includes(window.origin)) {
        console.log('here')
        history.back()
    } else {
        console.log('here2')
        window.location.href = window.origin
    }
}
async function setTagsWorkerData (res?: CallHistoryData | CallAiOnlyHistoryData) {
    const languageID = res?.ai_data?.languageData?.LanguageID
    await TagsWorker.init(languageID)
}
export async function fetchPdfCallData () {
    startGlobalLoading()
    return ApiManager.CallHistoryPdf()
        .then((res) => {
            setApiData(res)
        })
        .finally(stopLoading)
}

async function setApiData (callHistoryRes: CallHistoryData | CallAiOnlyHistoryData) {
    await setTagsWorkerData(callHistoryRes)
    setJWTPayloadData(callHistoryRes.jwt_payload)
    setEncryptedData(callHistoryRes.encrypted_data)
    setRecordingData(callHistoryRes)
    await setCdrData(callHistoryRes.cdr_data)
    await setAiData(callHistoryRes)
}

export async function fetchCallHistoryData (ignoreGlobal = false, withRelatedCals?: boolean) {
    if (!ignoreGlobal) {
        startLoading()
    }

    try {
        const { queryID } = getUrlQueryParams()

        const callHistoryRes = await ApiManager.CallHistory(queryID)
        await setApiData(callHistoryRes)
        if (withRelatedCals) {
            const rCals = await ApiManager.RelatedCalls()
            setRelatedCallsData(rCals)
        }
    } catch (e) {
        if (isWidgetState.value) {
            console.log('Reject')
            return Promise.reject(e)
        }
        onError()
    } finally {
        stopLoading()
    }
}
