import { use } from 'echarts/core'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import {
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    PolarComponent,
    ToolboxComponent,
    TooltipComponent
} from 'echarts/components'
import { BarChart, LineChart } from 'echarts/charts'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import VChart, { INIT_OPTIONS_KEY, THEME_KEY } from 'vue-echarts/csp'
import { EChartsOption, RegisteredSeriesOption } from 'echarts/types/dist/echarts'
import { CallbackDataParams } from 'echarts/types/dist/shared'

export type TVChart = typeof VChart
export type TRegisteredSeriesOption = RegisteredSeriesOption
export type TEChartsOption = EChartsOption
export type TCallbackDataParams = CallbackDataParams
export type TChartRender = 'canvas' | 'svg'

export {
    INIT_OPTIONS_KEY,
    THEME_KEY
}

export default function () {
    use([
        SVGRenderer,
        CanvasRenderer,
        GridComponent,
        BarChart,
        LineChart,
        LegendComponent,
        ToolboxComponent,
        TooltipComponent,
        DataZoomComponent,
        PolarComponent
    ])
}
