<template>
    <div class="w-full">
        <div
            v-if="viewPartState === VIEW_PART"
            class="w-full grid grid-cols-1 sm:grid-cols-2 justify-center items-center gap-3 lg:gap-20"
        >
            <div class="w-full">
                <div class="uppercase text-placeholders text-xs font-semibold mb-2">
                    {{ `${agentName || t('callHistoryView.noName')}:` }}
                </div>
                <div
                    class="w-full"
                    :style="{height: HEIGHT_PART}"
                >
                    <WordCloudChart
                        v-if="open"
                        key="part-1"
                        :keywords="caller1Words"
                        :height="HEIGHT_PART"
                    />
                </div>
            </div>
            <div class="w-full">
                <div class="uppercase text-placeholders text-xs font-semibold mb-2">
                    {{ `${clientName || t('callHistoryView.noName')}:` }}
                </div>
                <div
                    class="w-full"
                    :style="{height: HEIGHT_PART}"
                >
                    <WordCloudChart
                        v-if="open"
                        key="part-2"
                        :keywords="caller2Words"
                        :height="HEIGHT_PART"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="viewPartState === VIEW_ALL"
            class="flex-center"
        >
            <div
                class="w-2/3"
                :style="{ height: HEIGHT_ALL }"
            >
                <WordCloudChart
                    v-if="open"
                    key="all"
                    :keywords="[...caller1Words, ...caller2Words]"
                    :height="HEIGHT_ALL"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import WordCloudChart from '@/components/Chart/WordCloudChart.vue'
import { TKeyWordsSpeakerMapped } from '@/types/types'
import { useI18n } from 'vue-i18n'
import { agentName, clientName } from '@/composables/useAIData.ts'

const { t } = useI18n()

const VIEW_PART = 'part'
const VIEW_ALL = 'all'
const HEIGHT_ALL = '320px'
const HEIGHT_PART = '274px'

interface IProps {
    open?: boolean
    viewPartState?: string
    caller1Words?: Array<TKeyWordsSpeakerMapped>
    caller2Words?: Array<TKeyWordsSpeakerMapped>
}

withDefaults(
    defineProps<IProps>(),
    {
        viewPartState: 'part',
        caller1Words: () => [],
        caller2Words: () => []
    }
)
</script>
