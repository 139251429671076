/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get'
import set from 'lodash/set'
import getYear from 'date-fns/getYear'
import { formatDate } from '@/helpers/dateTime.helper.ts'
import { recordingDuration } from '@/composables/useCallRecording.ts'
import { ISipFlowMainData } from '@/types/sip'

const SOURCE_KEYS = [
    '@timestamp','@timestamp_hep', 'timestamp', 'timestamp_us',
    'srcip', 'dstip', 'src_port', 'dst_port', 'callid', 'messageType',
    'payload.method',
    'payload.data', 'payload.via', 'payload.call_id', 'payload.eventType', 'payload.Call-ID',
    'payload.status_code', 'payload.reason_phrase', 'payload.headers.User-Agent', 'payload.headers.Server'
]

export const sortMessagesFn = (a: any, b: any) => {
    const timestampA = get(a, 'objectData._source.@timestamp_hep', 0)
    const timestampB = get(b, 'objectData._source.@timestamp_hep', 0)
    return timestampA - timestampB
}
export const extractDataFromObject = (object: any, keys: Array<string>) => {
    const result = {} as any
    keys.forEach(itemKey => {
        const _val =  get(object, itemKey)
        set(result, itemKey, _val)
    })

    return result
}

export function getIntervalByCallDuration () {
    const _duration = recordingDuration.value
    if (_duration <= 30) {
        return '1s'
    } else if (_duration <= 60) {
        return '5s'
    } else if (_duration <= 120) {
        return '15s'
    } else if (_duration <= 240) {
        return '20s'
    } else if (_duration <= 360) {
        return '30s'
    } else if (_duration <= 480) {
        return '45s'
    }
    return '1m'
}

export const normalizeUrlQueryByDateRange = (url: string, date?: Date, searchValue = '{*}') => {
    if (!date) {
        return url.replace(searchValue, '*')
    }

    const _year = getYear(date)

    const _replaceVal = `*-${_year}`

    return url.replace(searchValue, _replaceVal)
}

// export const getUserAgentList = (callEvents: Array<ISipFlowRoot>) => {
//     // console.log('getUserAgentList', callEvents)
//     const _data = callEvents.map(item => {
//         return [
//             get(item, '_source.srcip', ''),
//             get(item, '_source.dstip', '')
//         ]
//     }).flat()
//     return [ ...new Set(_data) ].map(item => {
//         return {
//             AgentIP: item
//         }
//     })
// }
// export function getUserAgentAliasesList (callEvents: Array<ISipFlowRoot>) {
//     const _agents = getUserAgentList(callEvents)
//     return await getHepInfoData({ AgentData: _agents })
// }

export function getCallEventsMappedFunction (callEvents: Array<ISipFlowMainData>, aliasList: Record<string, string>, sorted = true) {
    const _resultMappedData = [] as Array<any>
    const _servers = [] as Array<any>
    callEvents.forEach((item: ISipFlowMainData, i: number) => {
        const _initObject = {
            _index: i,
            _id: `_data_${i + 1}`
        }
        const _source = extractDataFromObject(item, SOURCE_KEYS)
        const callId = item.callId || i
        const reason_phrase = item?.payload?.reason_phrase || ''
        const status_code = item?.payload?.status_code || ''
        const eventType = item?.payload?.eventType || ''
        const timestamp = item?.timestamp || 0
        const _from = item?.srcip || ''
        const _to = item?.dstip || ''
        const timestamp_us = item?.timestamp_us || 0
        const date = (+`${timestamp}${timestamp_us.toString().slice(0, 3)}`)
        const dateNano = get(item, '_source.@timestamp_hep', 0)
        const _message = reason_phrase || eventType
        const userAgentRaw = get(_source, 'payload.headers["User-Agent"]', '')
        const serverRaw = get(item, 'payload.headers["Server"]', '')
        const _serverName = userAgentRaw || serverRaw
        const _serverNameNormalized = _serverName.match(/^([a-zA-Z0-9]*)/g)[0] || ''

        const _finalFrom = aliasList[_from] || _from
        const _finalTo = aliasList[_to] || _to

        if (_finalFrom !== _from) {
            _servers.push(_from)
        }
        if (_finalTo !== _to) {
            _servers.push(_to)
        }

        _resultMappedData.push({
            objectData: {
                ..._initObject,
                _source: {
                    ..._source
                },
            },
            callId,
            from: _finalFrom,
            originalFrom: _from,
            to: _finalTo,
            originalTo: _to,
            message: status_code ? `${_message} (${status_code})` : _message,
            label: formatDate(date, 'yyyy-MM-dd hh:mm:ss SSS') + `.${timestamp_us.toString().slice(3)}`,
            eventType: eventType,
            reason_phrase,
            serverIcon: _serverNameNormalized,
            date,
            dateNano
        })
    })

    if (sorted) {
        return {
            data: _resultMappedData.sort(sortMessagesFn),
            aliases: [ ...new Set(_servers) ]
        }
    }
    return {
        data: _resultMappedData,
        aliases: [ ...new Set(_servers) ]
    }
}

