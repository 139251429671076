import uiColors from '@/theme/colors.json'

/* Agent & Caller */
export const AGENT_COLOR = '#127FFF' // agent
export const CALLER_COLOR = '#04E762' // client
/* CDR COLORS */
export const CDR_COLORS = {
    INCOMING: '#C63C49',
    CC: '#3C7BC6',
    UNKNOWN: '#C63C49',
    EXTENSION_OUTGOING: '#C6A83C',
    QUEUE: '#703CC6',
    CLICK_2_CALL_LEG_1: '#C66E3C',
    CLICK_2_CALL_LEG_2: '#4FB74D',
    VOICE_MAIL: '#B43440',
    CALLFERENCE: '#34A1A1',
    XFER_CDR: '#C6A83C',
    PRODUCTIVE_CALL_LEG_1: '#C66E3C',
    PRODUCTIVE_CALL_LEG_2: '#46B434',
    CLICK_2_IVR: '#C63C49',
    CLICK_2_IVR_INCOMING: '#C63C49',
    CLICK_2_QUEUE_INCOMING: '#C63C49',
    FAX_CDR: '#C63C49',
    ATTENDED_CDR_LEG_1: '#C63C49',
    ATTENDED_CDR_LEG_2: '#C63C49',
    AUTO_FORWARD: '#C63C49',
}
/* Default Emotion Color */
export const DEFAULT_EMOTION_COLOR = '#9EA6B2'
/* RTCP Colors */
export const RTCP_COLORS = [
    '#008080',
    '#8a2be2',
    '#FF4D4D',
    '#11CF2C',
    '#D34C4C',
    '#DB9200',
    '#0BCCE3',
    '#01deb1',
    '#FF9696',
    '#FF4D4D',
    '#c6e30b',
    '#523788',
    '#8f3d1f'
]
/* Main Colors */
export const COLORS = {
    uiColors: {
        ...uiColors
    },
    cdrColors: CDR_COLORS,
    rtcpColors: RTCP_COLORS,
    agentColor: AGENT_COLOR,
    clientColor: CALLER_COLOR,
    defaultEmotionColor: DEFAULT_EMOTION_COLOR,
    soundWavesColor: uiColors['ui-lines'] || '#EBEBEB',
    soundProgressColor: uiColors['primary'] || '#2675FF'
}
