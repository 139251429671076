/* Default Settings */
export const DEFAULT_HEIGHT = '274px'
// const DEFAULT_PRINT_HEIGHT = '220px'
export const DEFAULT_Y_MIN = -1.5
export const DEFAULT_Y_MAX = 1.5
export const SPLITTED_Y_MAX = 3.3
// const PDF_Y_MAX = 4
export const LINE_ICON_SCALE = 1.5
export const LINE_ICON_BLUR = 3
export const LINE_WIDTH = 3.5
export const LINE_ICON_SIZE = 24
export const ZOOM_DELTA = 0.2
export const MIN_ZOOM_SPAN = 5

export const LINES_RENDER_TYPE = 'svg'
