<template>
    <VcModal
        :append-to="modalAppendToElement"
        :visible="visible"
        :header="modalHeader"

        :breakpoints="{
            '960px': '80vw',
            '780px': '90vw',
            '680px': '98vw'
        }"
        @close="onCloseModal"
    >
        <div class="forms-content relative">
            <div
                v-for="category in localModel"
                :key="category.uid"
            >
                <div
                    class="flex items-center gap-2 font-semibold mb-2"
                >
                    <i
                        :class="category.icon"
                        class="icon-base text-primary-alternative-actions"
                    />
                    {{ category.name }}
                </div>
                <div class="px-5 flex flex-col w-auto gap-2">
                    <div
                        v-for="field in category.fields"
                        :key="`f-${field.uid}`"
                        class="grid grid-cols-[1fr_2fr] w-auto items-start gap-1"
                    >
                        <div class="flex items-center gap-2 font-medium">
                            <i
                                :class="field.icon"
                                class="text-icons"
                            />
                            {{ field.label }}:
                        </div>
                        <div class="whitespace-pre-line">
                            {{ field.value }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="w-full flex justify-end">
                <VcButton
                    icon="vc-lc-x"
                    @click="onCloseModal"
                >
                    {{ t('callHistoryView.close') }}
                </VcButton>
            </div>
        </template>
    </VcModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { IFormPreviewDataMapped, IFormsMappedData } from '@/types/api'
import { getFormDataMappedResult, getFormModelData } from '@/composables/useCallForms'
import { useI18n } from 'vue-i18n'
import { isWidgetState, usedWidgetShadowRootEl } from '@/services/GlobalState.ts'

const { t } = useI18n()

const visible = ref(false)
const modalHeader = ref('')
const localModel = ref<Array<IFormPreviewDataMapped>>([])

const modalAppendToElement = computed(() => {
    if (isWidgetState.value && !!usedWidgetShadowRootEl.value) {
        const el = usedWidgetShadowRootEl.value as HTMLElement
        return el || 'body'
    }
    return 'body'
})

function onCloseModal () {
    visible.value = false
}
function onOpenModal (data: IFormsMappedData) {
    localModel.value = getFormDataMappedResult(getFormModelData(data.FormID), { ...data })
    modalHeader.value = data.FormName || ''
    visible.value = true
}

defineExpose({
    close: onCloseModal,
    open: onOpenModal
})
</script>

