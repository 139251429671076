import { TEmotionCallerClickData, TKeyWordsSpeakerMapped } from '@/types/types'

export const TabAiProvideToken = Symbol('TabAiProvideToken')
export const TabAiDataTypeProvideToken = Symbol('TabAiDataTypeProvideToken')
export const GlobalDataProvideToken = Symbol('GlobalDataProvideToken')

export type TAiTabOptionsProvide = {
    wordCloudItemClick: (pl: TKeyWordsSpeakerMapped) => void
    emotionClickHandler: (pl: TEmotionCallerClickData) => void
}
