<template>
    <BaseDrawerSidebar
        ref="drawerSidebarRef"
        @close="closePanel"
    >
        <div class="w-full sip-preview-message">
            <div class="sip-preview-message__header flex items-center justify-between w-full p-3 border-b border-ui-lines">
                <span class="font-bold px-3">
                    {{ label }}
                </span>
                <span
                    class="vc-icon-close cursor-pointer text-inactive-elements hover:text-active-elements--focus"
                    @click="closePanel"
                />
            </div>
            <div class="sip-preview-message__content py-3 px-3">
                <div class="w-full flex justify-end items-center gap-2 sticky top-0 z-10">
                    <div class="bg-white">
                        <VcButton
                            :type="isFullJsonView ? 'outline' : 'default'"
                            size="small"
                            @click="isFullJsonView = false"
                        >
                            Text view
                        </VcButton>
                    </div>
                    <div class="bg-white">
                        <VcButton
                            :type="isFullJsonView ? 'default' : 'outline'"
                            size="small"
                            @click="isFullJsonView = true"
                        >
                            JSON view
                        </VcButton>
                    </div>
                </div>
                <div
                    class="w-full pt-2"
                    dir="ltr"
                >
                    <div
                        v-if="!isFullJsonView"
                        class="w-full border border-ui-lines rounded-xs p-2 divide-y divide-ui-lines divide-dashed"
                    >
                        <CallEventTextItemMessage
                            :message-item="messageData"
                        />
                    </div>
                    <VueJsonPretty
                        v-else
                        :data="messageData"
                        show-length
                        show-line
                        show-icon
                    />
                </div>
            </div>
        </div>
    </BaseDrawerSidebar>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'
import CallEventTextItemMessage from '@/components/Common/CallEventTextItemMessage.vue'
import VueJsonPretty from 'vue-json-pretty'
import BaseDrawerSidebar from '@/components/App/BaseDrawerSidebar.vue'
import { MessageFlowType } from '@voicenter-team/voicenter-ui-plus'

const emit = defineEmits([ 'close' ])

const drawerSidebarRef = ref<typeof BaseDrawerSidebar>()
const label = ref('')
const messageData = ref(undefined)
const isFullJsonView = ref<boolean>(false)

const openPanel = (data: MessageFlowType) => {
    const reason = data.reason_phrase || ''
    const message = data?.message || ''
    messageData.value = data?.objectData
    label.value = reason ? `${message}: ${reason}` : message
    nextTick(() => {
        drawerSidebarRef.value?.open()
    })
}

const closePanel = () => {
    drawerSidebarRef.value?.close()
    messageData.value = undefined
    emit('close')
}
defineExpose({
    open: openPanel,
    close: closePanel
})
</script>

<style lang="scss">
    .sip-preview-message {
        &__content {
            height: calc(100vh - 44px);
            overflow-y: auto;
        }
        &__header {
            @apply truncate;
            height: 44px;
        }
    }
</style>
