<template>
    <div class="emotional-lines-chart__wrap">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-2 px-3 lg:px-4 py-3 lg:py-6">
            <div class="block-title">
                {{ t('callHistoryView.ai.emotionTimeline') }}
            </div>
            <div class="flex items-center justify-end">
                <BlockAction
                    :icon="splitted ? 'vc-lc-line-chart' : 'vc-lc-square-split-vertical'"
                    :label="splitted ? t('callHistoryView.ai.timelineUnited') : t('callHistoryView.ai.timelineSplit')"
                    class="gap-x-2"
                    @click="splitted = !splitted"
                />
            </div>
        </div>
        <div
            class="w-full emotional-lines-chart relative"
            dir="ltr"
        >
            <v-chart
                ref="chartRef"
                class="emotional-lines-chart__chart"
                :option="chartOptions"
                autoresize
                :style="{ height: chartHeight }"
                @finished="onFinished"
                @click="onClick"
            />
            <div class="chart-toolbar absolute top-0 right-0 bg-light-bg px-3 lg:px-4 flex-center gap-1">
                <VcButtonIcon
                    type="plain"
                    color="primary-alternative"
                    :title="t('zoomIn')"
                    icon="vc-lc-zoom-in"
                    @click="zoomIn"
                />
                <VcButtonIcon
                    type="plain"
                    color="primary-alternative"
                    :title="t('zoomOut')"
                    icon="vc-lc-zoom-out"
                    @click="zoomOut"
                />
                <VcButtonIcon
                    type="plain"
                    color="primary-alternative"
                    :title="t('scrollLeft')"
                    icon="vc-lc-chevrons-left"
                    @click="moveLeft"
                />
                <VcButtonIcon
                    type="plain"
                    color="primary-alternative"
                    :title="t('scrollRight')"
                    icon="vc-lc-chevrons-right"
                    @click="moveRight"
                />
                <VcButtonIcon
                    type="plain"
                    color="primary-alternative"
                    :title="t('resetZoom')"
                    icon="vc-lc-refresh-ccw"
                    @click="resetZoom"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, nextTick, provide, ref, watch } from 'vue'
import { TMessageItem } from '@/types/types'
import { toMinutes } from '@/helpers/dateTime.helper'
import BlockAction from '@/components/Common/BlockAction.vue'
import { getMessageByTime } from '@/composables/useAiTabData.ts'
import { AGENT_SPEAKER, CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { recordingDuration } from '@/composables/useCallRecording.ts'
import useEmotionalLines from '@/composables/useEmotionalLines.ts'
import TagsWorker from '@/services/TagsWorker.ts'
import { useI18n } from 'vue-i18n'
import {
    DEFAULT_HEIGHT,
    DEFAULT_Y_MAX,
    DEFAULT_Y_MIN,
    LINE_ICON_BLUR,
    LINE_ICON_SCALE,
    LINE_ICON_SIZE,
    LINE_WIDTH,
    LINES_RENDER_TYPE,
    MIN_ZOOM_SPAN,
    SPLITTED_Y_MAX,
    ZOOM_DELTA
} from '@/enums/emotionChart.enum.ts'
import { agentName, clientName } from '@/composables/useAIData.ts'
import { AgentColor, ClientColor, DEFAULT_CHART_THEME } from '@/composables/useColorsComposable.ts'
import {
    INIT_OPTIONS_KEY,
    TChartRender,
    THEME_KEY,
    TRegisteredSeriesOption,
    TVChart
} from '@/composables/useEChartComponents.ts'

const { t } = useI18n()
const { SeriesData, defaultLegendOptions, defaultGridOptions } = useEmotionalLines()

/* Types */
type Values<T> = T[keyof T]

/* Props */
const props = defineProps<{
    theme?: string
    renderer?: TChartRender
    height?: string
}>()
const emits = defineEmits([ 'finished', 'click' ])

/* Chart Global Settings */
const initOptions = computed(() => ({
    renderer: props.renderer || LINES_RENDER_TYPE
}))

provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

const seriesDefaultOptions: Values<TRegisteredSeriesOption> = {
    type: 'line',
    lineStyle: {
        width: LINE_WIDTH,
        cap: 'round',
    },
    smooth: true,
    zlevel: 1,
    showSymbol: true,
    symbolSize (pointData) {
        if (pointData[4]) {
            return LINE_ICON_SIZE
        }
        return 0
    },
    symbol (rawValue: any) {
        return rawValue[4]
    }
}

/* Data */
const chartRef = ref<TVChart>()
const splitted = ref<boolean>(true)

const chartHeight = computed(() => {
    return props.height || DEFAULT_HEIGHT
})
const chartOptions = computed(() => {

    const series1Name = (agentName.value || t('callHistoryView.agent'))
    const series2Name = (clientName.value || t('callHistoryView.client'))

    const { series1, series2 } = getSplittedSeries()
    return {
        legend: {
            ...defaultLegendOptions
        },
        grid: {
            ...defaultGridOptions
        },
        dataZoom: [
            {
                type: 'inside',
                disabled: false,
                zoomOnMouseWheel: true,
                moveHandleSize: false,
                moveOnMouseWheel: 'ctrl',
                realtime: true,
                filterMode: 'none',
                rangeMode: [ 'value', 'value' ],
                minValueSpan: MIN_ZOOM_SPAN

            }
        ],
        tooltip: {
            show: true,
            trigger: 'axis',
            textStyle: { color: 'black' },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter (params: any) {
                const color = params[0]?.color
                const data = params[0]?.data || []
                const speaker = data[2] || ''
                const icon = data[4] || ''
                const from = data[0]
                if (!speaker || !icon) {
                    return undefined
                }
                // const to = data[5]
                return `<div dir="${TagsWorker.isRtl ? 'rtl' : 'ltr'}" style="overflow: hidden">
                ${speaker} &nbsp;<span class="" style="display: inline-block; border-radius: 100%; width: 8px; height: 8px; background-color: ${color};"></span>
                 <br>
                 <span class="text-xs">${toMinutes(from)}</span>&nbsp;<span class="text-xs capitalize">(${data[5]})</span>
                 <br>
                 <div class="truncate" style="max-width: 200px; white-space: normal; font-style: italic;"> "${data[3]}"</div>
             </div>`
            }
        },
        yAxis: {
            show: true,
            axisLabel: { show: false },
            min: DEFAULT_Y_MIN,
            max: SPLITTED_Y_MAX,
            splitLine: {
                lineStyle: { type: 'dashed' }
            },
            type: 'value',
            silent: true
        },
        xAxis: {
            type: 'time',
            min: 0,
            max: +recordingDuration.value,
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                    color: 'var(--ui-lines, #BFBFBF)',
                    width: 1,
                }
            },
            axisLabel: {
                formatter (data: number) {
                    return toMinutes(data)
                },
                color: 'var(--default-text, black)',
                showMinLabel: true,
                showMaxLabel: true,
                interval: 0
            },
            axisTick: {
                show: false
            },
            axisPointer: {
                show: true,
                type: 'line',
                snap: false,
                triggerTooltip: true,
                triggerEmphasis: true,
                triggerOn: 'none',
                label: {
                    show: false
                },
                lineStyle: {
                    type: 'solid',
                    color: 'var(--ui-lines, #BFBFBF)',
                    width: 1,
                },
                handle: {
                    show: true,
                    size: 0
                },
                status: 'show',
                value: 0,
                zlevel: 0,
            }
        },
        series: [
            {
                ...seriesDefaultOptions,
                name: series1Name,
                data: [
                    ...series1
                ],
                color: AgentColor.value,
                emphasis: {
                    scale: LINE_ICON_SCALE,
                    focus: 'none',
                    itemStyle: {
                        shadowBlur: LINE_ICON_BLUR,
                        shadowColor: AgentColor.value
                    }
                }
            },
            {
                ...seriesDefaultOptions,
                name: series2Name,
                data: [
                    ...series2
                ],
                color: ClientColor.value,
                emphasis: {
                    scale: LINE_ICON_SCALE,
                    focus: 'none',
                    itemStyle: {
                        shadowBlur: LINE_ICON_BLUR,
                        shadowColor: ClientColor.value
                    }
                }
            }
        ]
    }
})

/* Methods */
function zoomIn () {
    applyZoom()
}
function zoomOut () {
    applyZoom(true)
}
function resetZoom () {
    chartRef.value?.dispatchAction({
        type: 'dataZoom',
        startValue: 0,
        endValue: recordingDuration.value
    })
}
function moveLeft () {
    move(true)
}
function moveRight () {
    move()
}
function move (isNegative?: boolean) {
    const { range, endValue, startValue } = getZoomRangeValues()
    if (range < recordingDuration.value) {
        const div = isNegative ? -1 : 1
        const delta = (range / 10) * div
        const newEnd = endValue + delta
        const newStart = startValue + delta
        chartRef.value?.dispatchAction({
            type: 'dataZoom',
            startValue: newStart,
            endValue: newEnd
        })
    }

}
function getZoomRangeValues () {
    const updatedOption = chartRef.value?.getOption()
    const dataZoomComponent = updatedOption.dataZoom[0]
    const startValue = dataZoomComponent?.startValue ?? 0
    const endValue = dataZoomComponent?.endValue ?? recordingDuration.value
    const range = endValue - startValue
    return {
        startValue,
        endValue,
        range
    }
}
function applyZoom (isOut?: boolean) {
    const { range, startValue: rangeStart } = getZoomRangeValues()

    const delta = isOut ? 1 + ZOOM_DELTA : 1 - ZOOM_DELTA
    const newRange = Math.round(range * delta)
    if (newRange < MIN_ZOOM_SPAN) {
        return
    }
    const rangeCenter = rangeStart + range / 2

    const newStart = Math.round(rangeCenter - newRange / 2)
    const newEnd = Math.round(rangeCenter + newRange / 2)

    chartRef.value?.dispatchAction({
        type: 'dataZoom',
        startValue: newStart,
        endValue: newEnd
    })
}
function onFinished () {
    emits('finished')
}
function getSplittedSeries () {
    const yAxisSplitDelta = 2
    const series1 = SeriesData.value.series1
    const series2 = SeriesData.value.series2.map((i: Array<any>) => {
        const j = [ ...i ]
        j[1] += yAxisSplitDelta
        return [ ...j ]
    })
    return {
        series1,
        series2
    }
}
function toggleSplitHandler () {
    if (splitted.value) {
        const { series1, series2 } = getSplittedSeries()
        chartRef.value?.setOption({
            yAxis: {
                max: SPLITTED_Y_MAX,
            },
            series: [
                { data: series1 }, { data: series2 }
            ]
        })
    } else {
        chartRef.value?.setOption({
            yAxis: {
                max: DEFAULT_Y_MAX,
            },
            series: [
                { data: SeriesData.value.series1 }, { data: SeriesData.value.series2 }
            ]
        })
    }
}
function reset () {
    chartRef.value?.setOption({
        xAxis: {
            axisPointer: {
                value: 0
            }
        }
    })
    nextTick(() => {
        chartRef.value?.dispatchAction({
            type: 'hideTip'
        })
        chartRef.value?.dispatchAction({
            type: 'highlight',
            seriesIndex: [ 0, 1 ],
            dataIndex: -1
        })
    })
}
function getDataIndex (_message: TMessageItem) {
    if (_message.speaker === CALLER_SPEAKER) {
        // eslint-disable-next-line
        return SeriesData.value.series1.findIndex((i: any) => i[6] === _message.sentence_id)
    }
    if (_message.speaker === AGENT_SPEAKER) {
        // eslint-disable-next-line
        return SeriesData.value.series2.findIndex((i: any) => i[6] === _message.sentence_id)
    }

    return 0
}
function movePointerInZoom (time: number) {
    const { range } = getZoomRangeValues()
    if (range < recordingDuration.value) {
        const centerRange = range / 2

        const startValue = time - centerRange
        const endValue = time + centerRange
        chartRef.value?.dispatchAction({
            type: 'dataZoom',
            startValue,
            endValue
        })
    }
}
function setTimePointer (time: number) {
    if (time > recordingDuration.value || !time) {
        reset()
        return
    }

    const _message = getMessageByTime(time)
    if (_message) {
        const dataIndex = getDataIndex(_message)
        const seriesIndex = _message.speaker === CALLER_SPEAKER ? 0 : 1
        chartRef.value?.dispatchAction({
            type: 'highlight',
            seriesIndex,
            dataIndex
        })
        chartRef.value?.dispatchAction({
            type: 'showTip',
            seriesIndex,
            dataIndex
        })
    }

    movePointerInZoom(time)

    chartRef.value?.setOption({
        xAxis: {
            axisPointer: {
                value: time
            }
        }
    })
}
function onClick (point: any) {
    emits('click', {
        time: point?.data[0] || 0,
        seriesIndex: point.seriesIndex
    })
}
function highlight (data: { label: string, type: string, user: number }) {
    const messages = data.user === 0 ? SeriesData.value.series1 : SeriesData.value.series2
    const t = messages.reduce((acc: Array<number>, el: Array<number | string>, index: number) => {
        if (el[el.length - 2] === data.type) {
            acc.push(index)
        }
        return acc
    }, [] as Array<number>)

    chartRef.value?.dispatchAction({
        type: 'downplay',
        seriesIndex: [ 0, 1 ]
    })

    chartRef.value?.dispatchAction({
        type: 'highlight',
        seriesIndex: data.user,
        dataIndex: t
    })
}

watch(
    splitted,
    () => {
        nextTick(() => {
            toggleSplitHandler()
        })
    }
)
defineExpose({
    setTimePointer,
    getDataIndex,
    reset,
    highlight,
    getDataURL: () => chartRef.value?.getDataURL(),
    toggle: () => splitted.value = !splitted.value
})
</script>

