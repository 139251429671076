<template>
    <div
        class="emoji-svg-icon"
        :style="styles"
        :title="name"
        v-html="getSvgRawIcon(name)"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { convertToUnit } from '@/helpers/general.helper.ts'
import { getSvgRawIcon } from '@voicenter-team/emotion-icons'

const props = defineProps<{
    name: string,
    size?: string | number
}>()

const styles = computed(() => {
    return {
        width: convertToUnit(props.size ?? 16),
        height: convertToUnit(props.size ?? 16)
    }
})
</script>

<style lang="scss">
    .emoji-svg-icon {
        @apply inline-flex items-center justify-center max-w-full max-h-full overflow-hidden;
        svg {
            @apply w-full h-full;
        }
    }
</style>
