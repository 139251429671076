<template>
    <div class="call-history-general-tab">
        <div class="call-history-general-tab__group">
            <div class="block-title mb-3 lg:mb-6">
                {{ mainData.blockTitle }}
            </div>
            <div class="call-history-general-tab__group-info">
                <CallInfoItemCard
                    v-for="(itemCard, i) in mainData.infoBlocks"
                    :key="`${mainData.key}-${i}`"
                    :content="itemCard"
                />
            </div>
        </div>
        <template v-if="!hasAiData && isRecording">
            <div class="call-history-general-tab__group">
                <div class="block-title mb-3 lg:mb-6">
                    {{ t('callHistoryView.general.recording') }}
                </div>
                <div class="w-full max-w-5xl">
                    <CallHistoryAiSound />
                </div>
            </div>
        </template>
        <div
            v-for="group in generalContent"
            :key="group.key"
            class="call-history-general-tab__group"
        >
            <div class="block-title mb-3 lg:mb-6">
                {{ group.blockTitle }}
            </div>
            <div class="call-history-general-tab__group-info">
                <CallInfoItemCard
                    v-for="(itemCard, i) in group.infoBlocks"
                    :key="`${group.key}-${i}`"
                    :content="itemCard"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getTranslateWithDefault } from '@/helpers/translate.ts'
import generalTabDataEnum from '@/enums/generalTabData.enum.ts'
import { useI18n } from 'vue-i18n'
import { getCdrData, technicalDataRef } from '@/composables/useCDRData.ts'
// import CallHistoryAiSound from '@/components/Common/CallHistoryAiSound.vue'
import CallInfoItemCard from '@/components/Card/CallInfoItemCard.vue'
import { hasAiData } from '@/composables/useAIData.ts'
import { isRecording } from '@/composables/useCallRecording.ts'
import CallHistoryAiSound from '@/components/Common/CallHistoryAiSound.vue'

const { t } = useI18n()

type TTechnicalData = { title: string, content: string | number }

const technicalDataMap = computed(() => {
    return Object.entries(technicalDataRef.value || {})
        .reduce<Array<TTechnicalData>>((acc, curr) => {
            if (!curr[0].startsWith('Forms')) {
                const _key = curr[0].trim()
                acc.push({
                    title: getTranslateWithDefault(`callHistoryView.general.technical.${_key}`, _key),
                    content: curr[1]
                })
            }
            return acc
        },[])
})

const mainData = computed(() => {
    const cdrData = getCdrData()
    return {
        blockTitle: t('callHistoryView.general.mainDetails'),
        key: 'MainDetails',
        infoBlocks: [
            {
                title: t('callHistoryView.general.type'),
                content: cdrData?.cdr_type_name
            },
            {
                title: t('callHistoryView.general.status'),
                content: cdrData?.dialstatus_name
            },
            {
                title: t('callHistoryView.general.dialTime'),
                content: cdrData?.dialtime
            },
            {
                title: t('callHistoryView.general.price'),
                content: (cdrData?.cost_us_cents || 0).toFixed(3)
            }
        ]
    }
})

const generalContent = computed(() => {
    const cdrData = getCdrData()
    return generalTabDataEnum(cdrData, technicalDataMap.value)
})
</script>

<style lang="scss">
.call-history-general-tab {
    @apply w-full flex flex-col divide-y divide-ui-lines;
    &__group {
        @apply py-10;
        &:first-child {
            @apply pt-0;
        }
    }
    &__group-info {
        @apply flex flex-wrap gap-y-3 gap-x-4 lg:gap-x-6 xl:gap-x-10;

        .call-history-info-card {
            min-width: 172px;
        }
    }
}
</style>
