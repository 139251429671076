import { inject, provide } from 'vue'
import { GlobalDataProvideToken } from '@/tokens'


export type GlobalDataProvidedType = {
    openRelatedCallPage?: (id: string) => void
}

export function useGlobalDataProvide (options: GlobalDataProvidedType) {
    provide(GlobalDataProvideToken, options)
}

export function useGlobalDataInject () {
    return inject<GlobalDataProvidedType>(GlobalDataProvideToken, {})
}
