import { computed, ref } from 'vue'
import Decryptor from '@voicenter-team/decryptor-js'
import useNotifyService from '@/composables/useNotifyService.ts'
import { CallAiOnlyHistoryData, CallHistoryData } from '@/types/api'
import { hasRecordDecryptionData, recordDecryptionData } from '@/composables/useEncryptedData.ts'
import { calcRecordingDuration } from '@/helpers/apiData.helper.ts'

const decryptor = new Decryptor()

const STATUS_FORBIDDEN = 403

/* Data */
export const soundData = ref<string>('')
export const recordingDuration = ref<number>(0)
export const soundSource = ref<string>('')

/* Computed */
export const isRecording = computed(() => {
    return !!soundSource.value
})

/* Methods */
export function setRecordingData (data: CallHistoryData | CallAiOnlyHistoryData) {
    soundSource.value = data.cdr_data.callRecordURL || ''
    recordingDuration.value = calcRecordingDuration(data)
}
export async function fetchSoundData () {
    if (soundSource.value && !hasRecordDecryptionData.value) {
        const res = await fetch(soundSource.value)
        if (res.status === STATUS_FORBIDDEN) {
            useNotifyService.add({
                type: 'error',
                title: 'Forbidden!',
                message: 'You have no permission for this file!',
                group: 'bottom-right'
            })
            return
        }
        const blob = await res.blob()

        soundData.value = URL.createObjectURL(blob)
    }

    if (recordDecryptionData.value && soundSource.value) {
        const res = await decryptor.audio.decryptByData(
            soundSource.value,
            recordDecryptionData.value
        )

        const audio = res?.getWav()

        if (!audio) {
            return
        }

        return soundData.value = URL.createObjectURL(audio)
    }
}
