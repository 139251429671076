<template>
    <div
        class="related-calls__item rounded shadow-card hover:shadow-card-hover transition divide-x divide-ui-lines"
    >
        <div class="flex px-3 lg:px-5">
            <div class="flex-center">
                <CdrTypeIcon
                    v-if="cdrTypeData"
                    :name="cdrTypeData.icon || 'default'"
                    :color="cdrTypeData.color"
                />
            </div>
            <div class="px-5 py-3 grid grid-cols-1 md:grid-cols-2 gap-4 w-full truncate">
                <div class="flex items-center md:items-start flex-row md:flex-col gap-2 flex-wrap md:flex-nowrap">
                    <div class="text-xs uppercase font-semibold text-placeholders">
                        {{ t('callHistoryView.relatedCalls.caller') }}
                    </div>
                    <div class="">
                        {{ getCallerName(callData) }}
                    </div>
                </div>
                <div class="flex items-center md:items-start flex-row md:flex-col gap-2 flex-wrap md:flex-nowrap">
                    <div class="text-xs uppercase font-semibold text-placeholders">
                        {{ t('callHistoryView.relatedCalls.destination') }}
                    </div>
                    <div class="">
                        {{ getTargetName(callData) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="p-3 flex items-center">
            <div class="w-full">
                <VcSoundPlayerWrapper
                    v-if="callData.callRecordURL"
                    ref="soundPlayerWrapperRef"
                    :src="soundData"
                    :sound-id="callData.iVR_unique_id"
                    :options="playerOptions"
                    :skip-seconds="skipNumber"
                    :default-volume="defaultValue"
                    :class="{'animate-pulse': preload}"
                    :disabled="preload"
                    class="w-full"
                    show-hover
                    @ready="onReady"
                    @start="onStart"
                    @stop="onStop"
                >
                    <VcSoundPlayerError
                        v-if="audioErrorMessage"
                        :audio-error-message="audioErrorMessage"
                    />
                    <div
                        v-else
                        class="w-full flex items-center gap-x-2 relative"
                    >
                        <VcPlayerPlayStopButton
                            icon-class="icon-2xl"
                            class="gap-x-3"
                        />
                        <VcSoundCurrentTime
                            class="text-xs leading-none "
                        />
                        <div class="flex-grow">
                            <VcSoundPlayerProgress
                                class="h-5"
                                show-by-init
                            />
                        </div>
                        <VcSoundDuration
                            class="text-xs leading-none"
                        />
                        <VcSoundVolume
                            :disabled="!soundData"
                        />
                    </div>
                </VcSoundPlayerWrapper>
            </div>
        </div>
        <div class="p-3 xl:px-4 flex items-center justify-end md:justify-center">
            <i
                v-if="openRelatedCallPage"
                class="vc-icon-open text-lg cursor-pointer hover:text-active-elements--focus"
                @click="openCallHistory"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { IRelatedCallItem } from '@/types/api'
import { computed, ref } from 'vue'
import Decryptor from '@voicenter-team/decryptor-js'
import {
    VcPlayerPlayStopButton,
    VcSoundCurrentTime,
    VcSoundDuration,
    VcSoundPlayerError,
    VcSoundPlayerProgress,
    VcSoundPlayerWrapper,
    VcSoundVolume
} from '@voicenter-team/voicenter-ui-plus'
import { WaveSurferOptions } from 'wavesurfer.js'
import cdrTypesEnum from '@/enums/cdrTypes.enum.ts'
import CdrTypeIcon from '@/components/Icons/CdrTypeIcon.vue'
import { getCallerName, getTargetName } from '@/helpers/cdrData.helper.ts'
import { useI18n } from 'vue-i18n'
import { SoundProgressColor, SoundWavesColor } from '@/composables/useColorsComposable.ts'
import { useGlobalDataInject } from '@/composables/useGlobalProvideInject.ts'

const { t } = useI18n()
const decryptor = new Decryptor()
const { openRelatedCallPage } = useGlobalDataInject()

const props = defineProps<{
    callData: IRelatedCallItem
    currentPlay?: string
}>()
const emit = defineEmits<{
    (e: 'play', pl: string): void
    (e: 'stop', pl: string): void
}>()

/* Player Options */
const skipNumber = 15
const defaultValue = 50
const playerOptions = computed<WaveSurferOptions>(() => {

    return {
        container: '',
        height: 20,
        waveColor: SoundWavesColor.value,
        progressColor: SoundProgressColor.value,
        barWidth: 2,
        cursorColor: 'var(--progress-line-not-done)'
    }
})

const soundData = ref('')
const audioErrorMessage = ref('')
const isPlaying = ref<boolean>(false)
const preload = ref<boolean>(false)
const soundPlayerWrapperRef = ref<typeof VcSoundPlayerWrapper>()

const cdrTypeData = computed(() => {
    const key = props.callData.cdr_type
    return (cdrTypesEnum[key] || cdrTypesEnum[0])
})

function onStop () {
    isPlaying.value = false
    emit('stop', props.callData.iVR_unique_id)
}
function openCallHistory () {
    if (openRelatedCallPage) {
        openRelatedCallPage(props.callData.iVR_unique_id)
    }
}
function onStart () {
    isPlaying.value = true
    emit('play', props.callData.iVR_unique_id)
    if (!soundData.value) {
        fetchSoundData()
    }
}
async function fetchSoundData () {
    if (!props.callData.callRecordURL) {
        return
    }
    try {
        preload.value = true
        const res = await decryptor.audio.decryptByData(
            props.callData.callRecordURL,
            props.callData.record_decryption_data
        )
        const audio = res?.getWav()

        if (!audio) {
            preload.value = false
            return
        }

        soundData.value = URL.createObjectURL(audio)
        preload.value = false
    } catch (e) {
        audioErrorMessage.value = 'Sound error!'
        preload.value = false
    }
}
function onReady () {
    const totalTime = props.callData.sec_total - props.callData.SecondsWaitingInQueue
    soundPlayerWrapperRef.value?.setDurationTime(totalTime)
}
</script>

<style lang="scss">
    .related-calls__item {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: span 3;
    }
</style>
