import i18n from '@/plugins/i18n.ts'
import { ColumnType } from '@voicenter-team/voicenter-ui-plus'

const { t } = i18n.global

const callFormsTableColumns: ColumnType = [
    {
        columnItemKey: 'name-column',
        rowItemKeyKey: 'name-row',
        rowItemLabelKey: 'FormName',
        columnItemLabel: t('callHistoryView.forms.table.name'),
        sortBy: 'FormName',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'system-column',
        rowItemKeyKey: 'system-row',
        rowItemLabelKey: 'FormSystemName',
        columnItemLabel: t('callHistoryView.forms.table.system'),
        sortBy: 'FormSystemName',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'questions-column',
        rowItemKeyKey: 'questions-row',
        rowItemLabelKey: 'Questions',
        columnItemLabel: t('callHistoryView.forms.table.questions'),
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'answers-column',
        rowItemKeyKey: 'answers-row',
        rowItemLabelKey: 'Answers',
        columnItemLabel: t('callHistoryView.forms.table.answers'),
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'preview-column',
        rowItemKeyKey: 'preview-row',
        rowItemLabelKey: '',
        columnItemLabel: t('callHistoryView.actions'),
        columnAlignment: 'left',
        rowItemAlignment: 'center',
        width: '120px'
    }
]

export default callFormsTableColumns
