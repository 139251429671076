/* eslint-disable @typescript-eslint/no-explicit-any */
import { onMounted, ref } from 'vue'
import { SipMessageFlowType } from '@voicenter-team/voicenter-ui-plus'
import { getCallEventsMappedFunction } from '@/helpers/sipFlow.helper.ts'
import ApiManager from '@/services/API/ApiManager.ts'
import { ISipFlowAlias } from '@/types/sip'

async function fetchData () {
    return await ApiManager.getSipFlowData()
}

function getMappedAliases (originalData: Array<ISipFlowAlias>) {
    return originalData.reduce((acc, item) => {
        if (item.AgentIpAlias.length) {
            item.AgentIpAlias.forEach(iAlias => {
                acc[iAlias] = item.AgentIP
            })
        } else {
            if (!acc[item.AgentIP]) {
                acc[item.AgentIP] = item.AgentIP
            }
        }
        return acc
    }, {} as Record<string, string>)
}

export default function () {
    const dataLoading = ref<boolean>(true)
    const dataMapped = ref<Array<SipMessageFlowType>>([])
    const aliases = ref<Record<string, string>>({})

    onMounted(async () => {
        try {
            dataLoading.value = true
            const response = await fetchData()
            aliases.value = getMappedAliases(response.aliases ?? [])
            const mappedData = getCallEventsMappedFunction(response.mainData, aliases.value)
            dataMapped.value = mappedData.data
            dataLoading.value = false
        } catch (e) {
            dataLoading.value = false
        }
    })

    return {
        dataLoading,
        dataMapped
    }
}
