<template>
    <div
        :style="{
            '--color': color || DEFAULT_COLOR,
            '--stroke': strokeColor || DEFAULT_STROKE
        }"
        class="svg-loader"
    >
        <div class="flex-center px-5 max-w-[90vw]">
            <svg
                class="logo2"
                width="400px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 163 39"
                fill="none"
            >
                <g clip-path="url(#clip0)">
                    <path
                        style="--i: 1"
                        class="dark-path"
                        d="M7.12998 38.8699C5.38998 38.8699 3.98998 38.4599 2.97998 37.6499C2.07998 36.9799 1.39998 36.0999 0.88998 34.9699C0.33998 33.8099 0.0299805 32.4499 -0.0200195 30.9199V8.07988C0.0299805 6.58988 0.33998 5.24988 0.88998 4.09988C1.42998 2.89988 2.10998 1.99988 2.96998 1.35988C4.03998 0.549883 5.47998 0.129883 7.13998 0.129883C8.77998 0.129883 10.17 0.539883 11.25 1.35988C12.38 2.19988 13.03 3.30988 13.38 4.08988C13.93 5.24988 14.24 6.58988 14.29 8.08988V30.9299C14.24 32.4499 13.93 33.8099 13.38 34.9599C12.89 36.0499 12.17 36.9499 11.25 37.6399C10.21 38.4699 8.86998 38.8699 7.12998 38.8699ZM7.12998 4.76988C6.10998 4.76988 5.35998 5.38988 5.00998 6.50988C4.85998 6.91988 4.78998 7.42988 4.78998 8.06988V30.9299C4.78998 32.1099 5.07998 32.9799 5.69998 33.6599C6.06998 34.0299 6.56998 34.2299 7.13998 34.2299C7.88998 34.2299 8.86998 33.9299 9.25998 32.4699C9.40998 32.1299 9.48998 31.6299 9.48998 30.9299V8.06988C9.48998 6.87988 9.23998 6.06988 8.64998 5.35988C8.25998 4.97988 7.72998 4.76988 7.12998 4.76988Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 2"
                        class="dark-path"
                        d="M20.0898 38.4498V0.549805H32.7598V5.5098H24.8998V16.5998H30.1698V21.5598H24.8998V38.4498H20.0898Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 3"
                        class="dark-path"
                        d="M37.0298 38.4498V0.549805H49.6998V5.5098H41.8398V16.5998H47.1198V21.5598H41.8398V38.4498H37.0298Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 4"
                        class="dark-path"
                        d="M58.7802 0.549805H53.9702V38.4498H58.7802V0.549805Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 5"
                        class="dark-path"
                        d="M71.7298 38.8699C69.9898 38.8699 68.5898 38.4599 67.5698 37.6499C66.6798 36.9799 65.9898 36.0999 65.4798 34.9699C64.9298 33.8099 64.6198 32.4499 64.5698 30.9199V8.07988C64.6198 6.58988 64.9298 5.24988 65.4798 4.09988C66.0198 2.89988 66.6998 2.00988 67.5598 1.35988C68.6298 0.549883 70.0698 0.129883 71.7298 0.129883C73.3698 0.129883 74.7498 0.539883 75.8398 1.35988C76.7298 2.02988 77.4698 2.96988 77.9698 4.08988C78.5198 5.23988 78.8298 6.58988 78.8798 8.08988V12.9599H74.4398V8.06988C74.4398 6.90988 74.1398 6.06988 73.4798 5.33988C73.1098 4.96988 72.5298 4.76988 71.8298 4.76988C70.8298 4.76988 70.1098 5.34988 69.6698 6.47988C69.4998 6.93988 69.4398 7.42988 69.3798 8.01988V30.9199C69.4298 32.0799 69.7598 32.9499 70.3898 33.6499C70.7598 34.0199 71.2598 34.2199 71.8298 34.2199C73.4398 34.2199 73.9598 33.2299 74.2098 32.4899C74.3698 32.1199 74.4398 31.6199 74.4398 30.9199V26.0399H78.8798V30.9199C78.8298 32.4399 78.5198 33.7999 77.9698 34.9499C77.4798 36.0399 76.7598 36.9399 75.8398 37.6299C74.8098 38.4699 73.4698 38.8699 71.7298 38.8699Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 6"
                        class="dark-path"
                        d="M84.27 38.4498V0.549805H96.41V5.5098H89.07V16.5998H94.35V21.9298H89.07V33.4898H96.41V38.4498H84.27Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 7"
                        class="primary-path"
                        d="M121.01 0.549805H116.2V38.4498H121.01V0.549805Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 8"
                        class="primary-path"
                        d="M138.63 38.4498L131.51 15.2898V38.4498H127.18V0.549805H131.46L138.58 23.7098V0.549805H142.91V38.4498H138.63Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 9"
                        class="primary-path"
                        d="M106.41 6.9898C104.63 6.9898 103.19 5.5398 103.19 3.7698C103.19 1.9998 104.64 0.549805 106.41 0.549805C108.19 0.549805 109.63 1.9998 109.63 3.7698C109.63 5.5398 108.18 6.9898 106.41 6.9898Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 10"
                        class="primary-path"
                        d="M101.52 38.4498V12.9498H105.96V15.6998C106.75 13.8098 108.82 12.5998 110.87 12.5398C110.93 12.5298 111 12.5298 111.08 12.5298H111.29V17.4098C110.97 17.3498 110.63 17.3198 110.26 17.3198C109.37 17.3198 108.23 17.4398 107.26 18.3398C106.11 19.2998 105.96 20.3798 105.96 21.3098V38.4398H101.52V38.4498Z"
                        fill="currentColor"
                    />
                    <path
                        style="--i: 11"
                        class="primary-path"
                        d="M163.02 13.5896V8.08965C162.97 6.58965 162.66 5.24965 162.11 4.08965C161.91 3.64965 161.61 3.10965 161.19 2.56965C161.18 2.55965 161.18 2.55965 161.17 2.54965C161.01 2.33965 160.82 2.12965 160.62 1.92965C160.61 1.91965 160.6 1.90965 160.58 1.89965C160.39 1.71965 160.19 1.53965 159.97 1.36965C158.88 0.549648 157.5 0.139648 155.86 0.139648C154.21 0.139648 152.77 0.569648 151.69 1.36965C150.83 2.01965 150.15 2.90965 149.61 4.10965C149.06 5.25965 148.75 6.59965 148.7 8.08965V30.9296C148.75 32.4596 149.06 33.8296 149.61 34.9796C150.12 36.1196 150.8 36.9896 151.7 37.6596C152.71 38.4696 154.11 38.8797 155.86 38.8797C157.59 38.8797 158.94 38.4797 159.97 37.6497C160.9 36.9597 161.61 36.0497 162.1 34.9697C162.65 33.8197 162.96 32.4596 163.01 30.9396V26.2996V26.2896V20.0396H155.93V23.9496H158.2V31.4196C158.2 31.5396 158.18 31.6596 158.16 31.7596C158.06 32.5196 157.79 33.2096 157.36 33.6596C156.96 34.0796 156.4 34.2397 155.85 34.2397C154.55 34.2397 153.5 32.8396 153.5 31.1096C153.5 31.0796 153.51 31.0496 153.51 31.0196C153.51 30.9996 153.51 30.9896 153.5 30.9696V8.06965C153.5 8.03965 153.5 8.01965 153.5 7.98965C153.56 7.40965 153.62 6.93965 153.79 6.47965C154.22 5.36965 154.93 4.78965 155.89 4.76965C156.47 4.77965 156.98 4.97965 157.35 5.34965C157.94 6.05965 158.19 6.86965 158.19 8.05965V13.5796H163.02V13.5896Z"
                        fill="currentColor"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect
                            width="163"
                            height="39"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
const DEFAULT_STROKE = '#2E60FF'
const DEFAULT_COLOR = 'transparent'

defineProps<{
    color?: string
    strokeColor?: string
}>()
</script>

<style scoped lang="scss">
.svg-loader {
    overflow: visible;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo2 {
        //color: var(--color, transparent);
        //fill: red;
        //stroke-width: .5px;
        //stroke-opacity: 1;
        /*<-- Play with this number until it look cool */
        path {
            animation: svgLoad 1.5s infinite linear;
            animation-delay: calc(var(--i) * .2s);
        }
        .dark-path {
            color: #292B2C;
        }
        .primary-path {
            color: var(--stroke, #2E60FF);
        }
    }

    @keyframes svgLoad {
        0%, 100% {
            opacity: 100%;
        }
        50% {
            opacity: 40%;
        }
    }
}
</style>
