import format from 'date-fns/format'

const FORMAT_DEFAULT = 'yyyy-MM-dd hh:mm:ss'

function padTo2Digits (num: number) {
    return num.toString().padStart(2, '0')
}

export function toMinutes (seconds?: number): string {
    if (seconds === undefined) {
        return '0:00'
    }
    const minutes = Math.floor(seconds / 60)
    const remainingSecondsInt = Math.floor(seconds - minutes * 60)
    let remainingSeconds = ''

    if (remainingSecondsInt < 10) {
        remainingSeconds = `0${remainingSecondsInt}`
    } else {
        remainingSeconds = `${remainingSecondsInt}`
    }

    return `${minutes}:${remainingSeconds}`
}

export default function getDateNumberSeconds (date: Date | number | string) {
    if (date instanceof Date) {
        return millisecondsToSeconds(date.getTime())
    } else if (typeof date === 'number') {
        const dateNumberString = date.toString()

        return dateTimeStampStringToSeconds(dateNumberString)
    } else {
        return dateTimeStampStringToSeconds(date)
    }
}

export function millisecondsToSeconds (ms: number) {
    const float = ms / 1000

    return parseInt(float.toFixed())
}

export function dateTimeStampStringToSeconds (date: string) {
    switch (date.length) {
        case 13:
            // Means it is milliseconds
            return millisecondsToSeconds(parseInt(date))
        case 10:
            return parseInt(date)
        default:
            throw new Error('Invalid date parameter')
    }
}

export function getParsedMessageTimeFromSeconds (time: number) {
    const _seconds = Math.round(time)

    if (time < 60 && _seconds >= 0) {
        return `00:${padTo2Digits(_seconds)}`
    } else if (_seconds >= 60 && _seconds < 3600) {
        const minutes = Math.floor(_seconds / 60)
        const seconds = _seconds - (minutes * 60)
        return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    }

    return getParsedHoursFromSeconds(time)
}

function getParsedHoursFromSeconds (time: number) {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time - (hours * 3600)) / 60)
    const seconds = time - (hours * 3600) - (minutes * 60)

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

export function getParsedTimeFromSeconds (time: number) {
    if (time < 60 && time > 0) {
        return `00:00:${padTo2Digits(time)}`
    }

    return getParsedHoursFromSeconds(time)
}

export const formatStringDate = (date: undefined | string | Date, formatString: string, fallbackValue = '') => {
    if (date === undefined) {
        return fallbackValue
    }

    try {
        const parsedDate = new Date(date)

        return format(parsedDate, formatString)
    } catch (e) {
        return fallbackValue
    }
}

export function formatDate (date?: number | string | Date, formatDate: string = FORMAT_DEFAULT) {
    if (!date) {
        return 'no data'
    }
    if (typeof date === 'number' || date instanceof Date) {
        return format(date, formatDate)
    } else {
        return format(new Date(date), formatDate)
    }
}
