<template>
    <div class="w-full">
        <VcSelect
            v-model="model"
            :multiple="true"
            class="mb-2"
            :config="campaignSelectorConfig"
            :options="campaignList"
        />
    </div>
</template>
<script lang="ts" setup>
import { ref, WritableComputedRef } from 'vue'
import { VcSelect } from '@voicenter-team/voicenter-ui-plus'
import { ExtendParameterDataType, TBaseParameterData, useDynamicComponent } from '@voicenter-team/form-renderer'
import { ObjectAnyType } from '@/types/types'

/* Types */
interface ICampSelectParameterData extends TBaseParameterData {
    defaultValue: Array<number>
}

type TCampSelectParameter = ExtendParameterDataType<'CustomParameter', ICampSelectParameterData>

/* Props */
type Props = {
    modelValue: object
    parameter: TCampSelectParameter
    disabled: boolean
}
const props = defineProps<Props>()

/* Emit */
const emit = defineEmits<{
    (e: 'update:modelValue', payload: Props['modelValue']): void
}>()

/* Data */
const parameterData: ICampSelectParameterData = {
    ...props.parameter.parameterData,
    defaultValue: []
}
const campaignSelectorConfig = {
    labelKey: 'CampaignName',
    valueKey: 'CampaignID'
} as const
// TODO: need to fix it in form-renderer
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const model = useDynamicComponent(props, parameterData, emit) as WritableComputedRef<number[]>
const campaignList = ref<Array<ObjectAnyType>>([])

/* Methods */
// const loadCampaignList = async () => {
//     const filter: CampaignFilterList = {
//         CampaignStatusIDList: CampaignEntity.entityTypeDefaultListStatuses
//     }
//
//     return CampaignEntity
//         .list(filter)
//         .then(res => {
//             campaignList.value = res
//         })
// }

/* Created */
// loadCampaignList()
</script>
