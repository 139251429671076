import i18n from '@/plugins/i18n'

const { t } = i18n.global

export function checkTranslateExist (key: string) {
    return t(key) !== key
}

export function getTranslateWithDefault (translateKey: string, defaultVal = '') {
    if (checkTranslateExist(translateKey)) {
        return t(translateKey)
    }
    return defaultVal
}
