<template>
    <div class="api-logs-tab">
        ApiLogsTab
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
