<template>
    <div
        :class="`cols-${colCount}`"
        class="form-render-item"
    >
        <FormRenderer
            v-if="model"
            ref="formRendererRef"
            v-model="model"
            :parameter-categories="categories"
            :disabled="disabled"
            :custom-components="customFormBuilderComponents"
            categories-display-type="plain"
        />
    </div>
</template>

<script setup lang="ts">
import { FormRenderer, TParametersCategory } from '@voicenter-team/form-renderer'
import { FormRendererModelType } from '@/types/form-renderer'
import { useVModel } from '@vueuse/core'
import { ref } from 'vue'
import customFormBuilderComponents from '@/components/Forms/CustomFormBuilderComponents'

interface Props {
    formName: string
    categories: Array<TParametersCategory>
    modelValue?: FormRendererModelType
    disabled?: boolean,
    colCount?: number
}

const props = withDefaults(defineProps<Props>(), {
    formName: '',
    categories: () => [],
    modelValue: () => ({}),
    disabled: false,
    colCount: 1
})

const emit = defineEmits<{ (e: 'update:modelValue', pl: FormRendererModelType | undefined): void }>()

const model = useVModel(props, 'modelValue', emit)

/* Refs */
const formRendererRef = ref<typeof FormRenderer>()

/* Methods */
async function validateForm () {
    const res: Array<{ isValid: boolean }> = await formRendererRef.value?.validateAllCategories()
    return res.every(i => i.isValid)
}

async function resetForm () {
    await formRendererRef.value?.resetAllCategoriesFields()
}

/* Exposed */
defineExpose({
    validateForm,
    resetForm
})
</script>

<style lang="scss">
.form-render-item {
    .vc-form {
        @apply grid grid-cols-1 gap-2 lg:gap-4;
    }
    &.cols-2 {
        .vc-form {
            @apply sm:grid-cols-2;
        }
    }
    &.cols-3 {
        .vc-form {
            @apply sm:grid-cols-2 lg:grid-cols-3;
        }
    }
    &.cols-4 {
        .vc-form {
            @apply sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
        }
    }
}
</style>
