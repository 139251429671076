<template>
    <div class="w-full emotions-polar-chart-block">
        <div class="emotions-polar-chart__content">
            <div class="emotions-polar-chart__header">
                <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-2 px-3 lg:px-4 py-3 lg:py-6">
                    <div class="block-title">
                        {{ t('callHistoryView.ai.polarChartTitle') }}
                    </div>
                    <div class="flex items-center justify-end">
                        <BlockAction
                            :icon="!aggregated ? 'vc-lc-radar' : 'vc-lc-square-split-horizontal'"
                            :label="!aggregated ? t('callHistoryView.ai.radarUnited') : t('callHistoryView.ai.radarSplit')"
                            class="gap-x-2"
                            @click="aggregated = !aggregated"
                        />
                    </div>
                </div>
            </div>
            <div class="emotions-polar-chart__chart">
                <v-chart
                    ref="chartRef"
                    class="emotions-polar-chart"
                    :option="polarChartOptions"
                    autoresize
                    :style="{ height: '480px' }"
                    @finished="onFinished"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'
import { activitySentences, getEmotionAbsoluteValue } from '@/composables/useAiTabData.ts'
import { AGENT_SPEAKER, CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { ObjectAnyType } from '@/types/types'
import { getSvgBase64Icon } from '@voicenter-team/emotion-icons'
import BlockAction from '@/components/Common/BlockAction.vue'
import { useI18n } from 'vue-i18n'
import { agentName, clientName } from '@/composables/useAIData.ts'
import { AgentColor, ClientColor, DEFAULT_CHART_THEME } from '@/composables/useColorsComposable.ts'
import { INIT_OPTIONS_KEY, TChartRender, THEME_KEY } from '@/composables/useEChartComponents.ts'
import { refDebounced } from '@vueuse/core'

const innerWidth = window.innerWidth
const iconSize = innerWidth > 960 ? 18 : 14

const { t } = useI18n()

type polarSeriesDataType = {
    agent: Record<string, number>
    client: Record<string, number>
}

/* Props */
const props = defineProps<{
    theme?: string
    renderer?: TChartRender
    height?: string
}>()
/* Emits */
const emit = defineEmits([ 'finished' ])

/* Constants */
const STACK = [ 'a', 'b' ]
const USER_KEYS: Array<keyof polarSeriesDataType> = [ 'agent', 'client' ] // for correct data sides
const COLORS = computed(() => {
    return {
        agent: AgentColor.value,
        client: ClientColor.value
    }
})
const defaultAngleAxisData = computed(() => {
    return {
        axisLine: {
            show: false
        },
        axisTick: {
            lineStyle: {
                color: 'var(--ui-lines)'
            }
        },
        axisLabel: {
            formatter (label: string) {
                return (innerWidth > 1264 || aggregated.value) ? `{icon|} \n {name|${label}}` : '{icon|}'
            },
            color: 'var(--default-text, black)',
            interval: 0,
            // interval: 'auto',
            rich: {
                name: {
                    color: 'var(--default-text, black)',
                    fontSize: 8,
                    align: 'center',
                    verticalAlign: 'center'
                }
            }
        }
    }
})

/* Data */
const time = ref(0)
const aggregated = ref(false)
const timeDebounced = refDebounced(time, 500, { maxWait: 1000 })

/* Chart Global Settings */
const initOptions = computed(() => ({
    renderer: props.renderer || 'svg'
}))
provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

/* Computed */
const seriesNames = computed(() => {
    return {
        agent: (agentName.value || t('callHistoryView.agent')),
        client: (clientName.value || t('callHistoryView.client'))
    }
})
const filteredSentencesByTime = computed(() => {
    const sentences = [ ...activitySentences.value ]
    const sentencesFiltered = sentences.filter(i => i.message?.startTime && i.message?.startTime <= timeDebounced.value)
    return sentencesFiltered.length ? [ ...sentencesFiltered ] : [ ...activitySentences.value ]
})
const filteredSentences = computed(() => {
    const sentences = filteredSentencesByTime.value
    const agentSentences = sentences.filter(el => el.speaker === AGENT_SPEAKER).sort((a, b) => (b.emotion_direction - a.emotion_direction))
    const clientSentences = sentences.filter(el => el.speaker === CALLER_SPEAKER).sort((a, b) => (a.emotion_direction - b.emotion_direction))
    return [ ...agentSentences, ...clientSentences ]
})
const sentencesDataMapped = computed(() => {
    return [ ...filteredSentences.value ].reduce<polarSeriesDataType>((acc, currentSentence) => {
        if (currentSentence.message) {
            const rate = getEmotionAbsoluteValue(currentSentence, 3)
            const emotion = currentSentence.emotion
            if (currentSentence.speaker === AGENT_SPEAKER) {
                if (!acc.agent[emotion]) {
                    acc.agent[emotion] = 0
                }
                acc.agent[emotion] += Math.abs(rate)
            } else if (currentSentence.speaker === CALLER_SPEAKER) {
                if (!acc.client[emotion]) {
                    acc.client[emotion] = 0
                }
                acc.client[emotion] +=  Math.abs(rate)
            }
        }
        return acc
    }, {
        agent: {},
        client: {}
    })
})
const polarChartOptions = computed(() => {
    if (aggregated.value) {
        return getSimpleChartOption()
    }
    return getSeparatedChartOption()
})
/* Methods */
function getSimpleChartOption () {
    const _sentences = sentencesDataMapped.value
    const legendData = []
    const agentEmotion = Object.keys(_sentences['agent']) || []
    const clientEmotion = Object.keys(_sentences['client']) || []
    const hasAgent = !!agentEmotion.length
    const hasClient = !!clientEmotion.length
    const setEmotions = [ ...new Set([ ...agentEmotion, ...clientEmotion ]) ]
    if (hasAgent) {
        legendData.push(seriesNames.value.agent)
    }
    if (hasClient) {
        legendData.push(seriesNames.value.client)
    }

    const series: Array<ObjectAnyType> = []
    USER_KEYS.forEach((key) => {
        const _name = seriesNames.value[key]
        const userEmotions = _sentences[key]
        series.push({
            type: 'bar',
            name: _name,
            data: setEmotions.map(emotionName => userEmotions[emotionName] ?? '-'),
            stack: 'a',
            coordinateSystem: 'polar',
            color: COLORS.value[key]
        })
    })
    return {
        tooltip: {},
        legend: {
            show: true,
            top: 0,
            data: [ ...legendData ].reverse()
        },
        angleAxis: {
            ...defaultAngleAxisData.value,
            axisLabel: {
                ...defaultAngleAxisData.value.axisLabel,
                interval: setEmotions.length > 30 ? 'auto' : 0,
                showMaxLabel: null
            },
            type: 'category',
            data: getAngleAxisDataMapped(setEmotions),
        },
        radiusAxis: {
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false }
        },
        polar: { center: [ '50%', '52%' ] },
        series
    }
}
function getSeparatedChartOption () {
    const _sentences = sentencesDataMapped.value
    const hasAgent = !!Object.keys(_sentences['agent']).length
    const hasClient = !!Object.keys(_sentences['client']).length

    const legendData: Array<string> = []
    const angleAxis: Array<ObjectAnyType> = []
    const series: Array<ObjectAnyType> = []
    const radiusAxis: Array<ObjectAnyType> = []
    const polar: Array<ObjectAnyType> = []

    const angleDiff = hasAgent && hasClient ? 180 : 360
    USER_KEYS.forEach((key, i) => {
        const _keys = Object.keys(_sentences[key] || {})
        const _name = seriesNames.value[key]
        const start = 90 - angleDiff * i
        const end = start - angleDiff
        legendData.push(_name)
        angleAxis.push({
            ...defaultAngleAxisData.value,
            axisLabel: {
                ...defaultAngleAxisData.value.axisLabel,
                formatter () {
                    return '{icon|}'
                }
            },
            type: 'category',
            polarIndex: i,
            startAngle: start,
            endAngle: end,
            data: getAngleAxisDataMapped(_keys)
        })
        radiusAxis.push({
            polarIndex: i,
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'var(--ui-lines)'
                }
            },
            axisLabel: { show: false },
            axisTick: { show: false }
        })
        polar.push({ center: [ '50%', '52%' ] })
        series.push({
            type: 'bar',
            polarIndex: i,
            name: _name,
            data: [ ...Object.values(_sentences[key] || {}) ],
            stack: STACK[i] || 'a',
            coordinateSystem: 'polar',
            color: COLORS.value[key]
        })
    })

    return {
        tooltip: {},
        legend: {
            show: true,
            top: 0,
            data: [ ...legendData ].reverse()
        },
        angleAxis,
        radiusAxis,
        polar,
        series
    }
}
function getAngleAxisDataMapped (data: Array<string>) {
    return [ ...data ].map(kItem => ({
        value: kItem,
        textStyle: {
            rich: {
                icon: {
                    height: iconSize,
                    width: iconSize,
                    align: 'center',
                    backgroundColor: {
                        image: `${getSvgBase64Icon(kItem.toLowerCase())}`
                    }
                }
            }
        }
    }))
}
function onFinished () {
    emit('finished')
}

/* Expose */
defineExpose({
    setTime: (val: number) => time.value = val
})
</script>
