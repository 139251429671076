import { computed, ref } from 'vue'
import ApiManager from '@/services/API/ApiManager.ts'
import { CategoryParameters, CdrData, Form, FormMap, FormParameterCategory, IFormsMappedData } from '@/types/api'
import { ObjectAnyType } from '@/types/types'
import { IFormParameterCategory } from '@/types/form-renderer'
import jsonpath from 'jsonpath'
import { TParametersCategory, TPartialDataParameter } from '@voicenter-team/form-renderer'
import { getCdrData, technicalDataRef } from '@/composables/useCDRData.ts'

/* Data */
export const FORM_DATA_LAST_UPDATED_KEY = 'formLastUpdatedDate' as const
export const formsLoadingState = ref<boolean>(false)
export const formListData = ref<Array<Form>>([])

/* Computed */
export const formsData = computed(() => {
    return technicalDataRef.value?.Forms
})
export const formsMappedData = computed(() => {
    const cdrData = getCdrData()
    if (!cdrData) {
        return []
    }
    return formListData.value.reduce((acc, curr) => {
        if (isCallFitsFormMapList(cdrData, curr.FormsMap)) {
            const _key = `f${curr.FormID}`
            const answersCount = getAnswersCount(formsData.value[_key])
            acc.push({
                ...curr,
                Questions: getQuestionsCount(curr.ParameterCategories || []),
                Answers: answersCount,
                FormData: getFormModelData(curr.FormID)
            })
        }
        return acc
    }, [] as Array<IFormsMappedData>)
})

/* Methods */
export function getFormModelData (formId?: number) {
    if (formId === undefined) {
        return {}
    }
    const _key = `f${formId}`
    return formsData.value[_key] ?? {}
}
export function getQuestionsCount (categories: Array<FormParameterCategory>) {
    return categories.reduce((acc, ci) => {
        acc += ci.CategoryParamteres?.length || 0
        return acc
    }, 0)
}
export function getAnswersCount (data?: ObjectAnyType) {
    if (!data) {
        return 0
    }
    return Object.entries(data).filter(i => i[0] !== 'formLastUpdatedDate' && i[1]).length
}
export function getCallFormsList () {
    formsLoadingState.value = true

    return ApiManager.FormsList()
        .then((res) => {
            formListData.value = res
        })
        .finally(() => formsLoadingState.value = false)
}


export const isCallFitsFormMap = (cdr: CdrData, formMap: FormMap) => {
    const formMapEntityId = formMap.EntityID
    let fits = false

    switch (formMap.EntityTypeID) {
        case (FORM_MAP_ENTITY_TYPE_LIST.USER):
            fits = cdr?.user_id === formMapEntityId
            break
        case (FORM_MAP_ENTITY_TYPE_LIST.ACCOUNT):
            fits = cdr.distributorID === formMapEntityId
            break
        case (FORM_MAP_ENTITY_TYPE_LIST.QUEUE):
            fits = cdr.queueID === formMapEntityId
            break
        case (FORM_MAP_ENTITY_TYPE_LIST.CAMPAIGN):
            fits = cdr.dialerData?.CampaignID === formMapEntityId
            break
    }
    return fits
}

export const isCallFitsFormMapList = (call: CdrData, formsMap: Array<FormMap> = []) => {
    return !formsMap.length || formsMap.some(formMap => isCallFitsFormMap(call, formMap))
}

export const FORM_MAP_ENTITY_TYPE_LIST = {
    ACCOUNT: 1,
    USER: 2,
    QUEUE: 3,
    CAMPAIGN: 4,
} as const

export const mapFormParameterCategories: (parameterCategories: Array<IFormParameterCategory>) => Array<TParametersCategory> = (parameterCategories = []) => {
    return parameterCategories.sort((a, b) => (a.ParameterCategoriesOrder ?? 0) - (b.ParameterCategoriesOrder ?? 0))
        .map<TParametersCategory>((formParameterCategory, index) => {
            const categoryId = formParameterCategory.CategoryID ?? index
            const categoryParameters = formParameterCategory.CategoryParamteres ?? []
            const categoryParametersFiltered = categoryParameters
                .filter(categoryParameter => categoryParameter.ParameterComponentTag)
                .sort((a, b) => (a.ParameterOrder ?? 0) - (b.ParameterOrder ?? 0))
            return {
                uid: categoryId,
                name: formParameterCategory.ParameterCategoryName ?? '',
                componentParameters: categoryParametersFiltered
                    .map<TPartialDataParameter>((categoryParameter, index) => {
                        const _data: TPartialDataParameter = {
                            uid: categoryParameter.ParametersID ?? index,
                            name: categoryParameter.ParameterName ?? '',
                            propJpath: categoryParameter.ParameterJPath ?? `$.${Date.now()}`,
                            type: categoryParameter.ParameterComponentTag,
                            parentId: categoryId,
                            parameterData: categoryParameter.ParameterData ?? {}
                        }
                        return _data
                    })
            }
        })
}

export const getFormDataMappedResult = (data: ObjectAnyType, formsData: IFormsMappedData) => {
    return (formsData.ParameterCategories || [])
        .sort((a, b) => (a.ParameterCategoriesOrder ?? 0) - (b.ParameterCategoriesOrder ?? 0))
        .map((formParameterCategory, index) => {
            const groupName = formParameterCategory.ParameterCategoryName ?? ''
            const groupIcon = formParameterCategory.ParameterCategoryIconSelector ?? ''
            const categoryParametersFiltered = (formParameterCategory.CategoryParamteres ?? [])
                .filter(categoryParameter => categoryParameter.ParameterComponentTag)
                .sort((a, b) => (a.ParameterOrder ?? 0) - (b.ParameterOrder ?? 0))
            return {
                uid: formParameterCategory.CategoryID ?? index,
                name: groupName,
                icon: groupIcon,
                fields: categoryParametersFiltered.map((categoryParameter, i) => {
                    const iconField = categoryParameter.ParameterData?.icon ?? ''
                    const label = categoryParameter.ParameterData?.title ?? ''

                    return {
                        uid: categoryParameter.ParametersID || i,
                        icon: iconField,
                        label: label,
                        value: getCategoryParameterValue(data, categoryParameter)
                    }
                })
            }
        })
}

export function getCategoryParameterValue (formsData: IFormsMappedData, param:  CategoryParameters) {
    const _val = jsonpath.query(formsData, param.ParameterJPath || '')[0]
    if (param.ParameterTag === 'ISelect') {
        const options = param.ParameterData?.options || []
        return options.find(i => i.value == _val)?.label || _val

    }
    if (param.ParameterTag === 'MISelect') {
        const _values = `${_val}`.startsWith('[') ? JSON.parse(_val) : _val
        const options = param.ParameterData?.options || []
        return options.filter(i => _values?.includes(i.value)).map(i => i.label).join(', ')
    }
    if (param.ParameterTag === 'CampSelect') {
        return Object.entries(_val).map(i => `${i[0]}: ${i[1]}`).join(', \n')
    }

    return _val
}


