<template>
    <img
        :src="imageSrc"
        :alt="emotion"
        class="w-16 h-auto"
    >
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import { getGifCdnIcon } from '@voicenter-team/emotion-icons'

const props = defineProps<{
    emotion: string
}>()

const imageSrc = computed(() => {
    const key = props.emotion?.toLowerCase()

    return getGifCdnIcon(key)
})
</script>
