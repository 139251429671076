import { App } from 'vue'
import NotifyService from '@/composables/useNotifyService'

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $notify: typeof NotifyService;
    }
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$notify = { ...NotifyService }
        // app.provide(globalNotifySymbol, NotifyService)
    }
}
