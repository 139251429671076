import { onMounted, ref } from 'vue'
import ApiManager from '@/services/API/ApiManager.ts'
import { IRtcpData, RtcpTimeLineItem } from '@/types/rtcp'

type TRtcpTimelineData = {
    avgMos: Array<number[]>,
    avgJitter: Array<number[]>,
    avgPctLoss: Array<number[]>
}

function formatNumber (num: number) {
    return Math.round(num * 1000) / 1000
}
export function rtcpTimelineMapDataFn (timelineData: Array<RtcpTimeLineItem>) {
    return  timelineData.reduce((acc, item) => {
        const _key = item.key
        acc.avgMos.push([ _key, formatNumber(item.Mos?.value ?? 0) ])
        acc.avgJitter.push([ _key, formatNumber(item.Jitter?.value ?? 0) ])
        acc.avgPctLoss.push([ _key, formatNumber(item.PacketLoss?.value ?? 0) ])
        return acc
    }, {
        avgMos: [],
        avgJitter: [],
        avgPctLoss: []
    } as TRtcpTimelineData)
}

export default function () {
    const dataLoading = ref<boolean>(true)
    const rtcpData = ref<Array<IRtcpData>>([])
    const rtcpTimelineData = ref<TRtcpTimelineData>({
        avgMos: [],
        avgJitter: [],
        avgPctLoss: []
    })

    onMounted(async () => {
        try {
            dataLoading.value = true
            const response = await ApiManager.getRTCPData()
            rtcpData.value = response.map((item, index) => {
                return {
                    ...item,
                    _id: `rtcp-item-${index + 1}`
                }
            })
            const timeline = await ApiManager.getSingleCallTimeline()
            rtcpTimelineData.value = rtcpTimelineMapDataFn(timeline)
            dataLoading.value = false
        } catch (e) {
            dataLoading.value = false
        }
    })

    return {
        dataLoading,
        rtcpData,
        rtcpTimelineData
    }
}
