import { computed, ref } from 'vue'
import { AGENT_COLOR, CALLER_COLOR, CDR_COLORS, COLORS } from '@/enums/colors.enum.ts'
import { TGlobalColorsData } from '@/types/colors'

export const DEFAULT_CHART_THEME = 'light'

/* Data */
const themeColorsRef = ref<TGlobalColorsData>({
    ...COLORS
})

/* Computed */
export const AgentColor = computed(() => {
    return themeColorsRef.value.agentColor || AGENT_COLOR
})
export const ClientColor = computed(() => {
    return themeColorsRef.value.clientColor || CALLER_COLOR
})
export const SoundWavesColor = computed(() => {
    return themeColorsRef.value.soundWavesColor || COLORS.soundWavesColor
})
export const SoundProgressColor = computed(() => {
    return themeColorsRef.value.soundProgressColor || COLORS.soundProgressColor
})

/* Methods */
export function setGlobalThemeColors (colors: TGlobalColorsData = COLORS) {
    themeColorsRef.value = { ...colors }
}
export function getDefaultEmotionColor () {
    return themeColorsRef.value.defaultEmotionColor || COLORS.defaultEmotionColor
}
export function getCDRColors () {
    return themeColorsRef.value.cdrColors || COLORS.cdrColors
}
export function getRTCPColors () {
    return themeColorsRef.value.rtcpColors || COLORS.rtcpColors
}
export function getCDRColorByKey (key: keyof typeof CDR_COLORS) {
    const colors = getCDRColors()
    return colors[key]
}
