<template>
    <VcCard class="related-calls__wrap py-3 lg:py-6 px-4 lg:px-8">
        <div class="related-calls__content w-full">
            <div class="flex items-center gap-2">
                <span class="font-semibold text-base">
                    {{ t('callHistoryView.relatedCalls.title') }}
                </span>
                <i class="vc-icon-info text-active-elements" />
            </div>
            <div
                class="related-calls__list pt-3 lg:pt-6"
            >
                <template v-if="!relatedCalls.length">
                    <div class="w-full rounded bg-inactive-elements-bg--focus flex-center py-5">
                        <div class="font-medium text-base">
                            {{ t('callHistoryView.noData') }}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="related-calls-list">
                        <CallRelatedCard
                            v-for="(call, i) in relatedCalls"
                            :key="`call-${i}`"
                            :call-data="call"
                        />
                    </div>
                </template>
            </div>
        </div>
    </VcCard>
</template>

<script setup lang="ts">
import CallRelatedCard from '@/components/Card/CallRelatedCard.vue'
import { computed } from 'vue'
import { relatedCallsData } from '@/composables/useRelatedCallsData.ts'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

/* Data */
const relatedCalls = computed(() => {
    return relatedCallsData.value
})
</script>

<style lang="scss">
.related-calls__wrap {
    .related-calls-list {
        @apply flex flex-col flex-wrap md:grid gap-y-4;
        grid-template-columns: min(35%, 380px) 1fr 42px;
    }
}
</style>
