<template>
    <div class="satisfaction-block flex flex-col sm:flex-row divide-x rtl:divide-x-reverse divide-y sm:divide-y-0 divide-ui-lines">
        <SpeakerEmotionsCard
            v-if="agentData"
            :data="agentData"
            :total-call-duration="totalCallDuration"
            :color="AgentColor"
            :user="USER_AGENT_TYPE"
        />
        <SpeakerEmotionsCard
            v-if="clientData"
            :data="clientData"
            :total-call-duration="totalCallDuration"
            :color="ClientColor"
            :user="USER_CLIENT_TYPE"
        />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ParticipantsDataExtended } from '@/composables/useAiTabData.ts'
import { USER_AGENT_TYPE, USER_CLIENT_TYPE } from '@/enums/aiTab.enum.ts'
import SpeakerEmotionsCard from '@/components/Card/SpeakerEmotionsCard.vue'
import { AgentColor, ClientColor } from '@/composables/useColorsComposable.ts'

interface IProps {
    agentData?: ParticipantsDataExtended
    clientData?: ParticipantsDataExtended
}

const props = defineProps<IProps>()

/* Computed */
const totalCallDuration = computed(() => {
    const agentTotalTime = props.agentData?.total_talking_time ?? 0
    const clientTotalTime = props.clientData?.total_talking_time ?? 0

    return agentTotalTime + clientTotalTime
})
</script>

