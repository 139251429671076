<template>
    <div
        :class="{'is--printed': printState}"
        class="speaker-emotions-card w-full"
    >
        <div class="speaker-emotions-card__title">
            <div
                class="user-head-info"
            >
                <div
                    class="user-head-info__title"
                >
                    <span
                        class="user-state-color"
                        :style="{ backgroundColor: color }"
                    />
                    <span class="user-head-info__user inline-flex">
                        {{ data.name || t('callHistoryView.noName') }}
                    </span>
                </div>
                <div class="user-head-info__data">
                    {{ speakerDataText }}
                </div>
            </div>
            <div
                class="flex-shrink-0 meter-group-data"
            >
                <VcMeterGroup
                    :value="emotionMetrics"
                    :max="0"
                    :height="printState ? '6px' : '8px'"
                    hide-label
                    class=""
                >
                    <template #meter="slotProps">
                        <span
                            :class="slotProps.class"
                            :title="slotProps.value.label"
                            :style="{
                                background: `linear-gradient(to ${isRtl ? 'left' : 'right'}, ${slotProps.value.color}, ${slotProps.value.color2})`,
                                width: slotProps.size

                            }"
                        />
                    </template>
                </VcMeterGroup>
            </div>
        </div>
        <div class="speaker-emotions-card__content">
            <div class="w-full flex flex-wrap lg:flex-nowrap gap-2">
                <div class="py-2 min-w-44 flex flex-col gap-1">
                    <div class="content-block-title">
                        {{ t('callHistoryView.ai.strongestEmotions') }}
                    </div>
                    <div
                        v-for="(item, i) in strongestEmotions"
                        :key="`${i}-emotion`"
                        class="flex items-center gap-1.5 mb-1 cursor-pointer h-4"
                        @click="clickEmotion(item.emotion)"
                    >
                        <div class="flex-center emoji-icon">
                            <EmojiSvgIcon :name="item.emotion" />
                        </div>
                        <div
                            class="emotion-name-item"
                            :style="{color: getEmotionColor(item.emotion)}"
                        >
                            {{ getTranslateWithDefault(`callHistoryView.ai.emotions.${item.emotion?.toLowerCase()}`, item.emotion) }}
                        </div>
                    </div>
                </div>
                <div class="py-2 min-w-44">
                    <div class="content-block-title">
                        {{ t('callHistoryView.ai.personalityTraits') }}
                    </div>
                    <div class="pt-2">
                        <div
                            v-for="item in data.personality_traits"
                            :key="item"
                            class="text-item mb-2"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getEmotionColor, getEmotionStrength, ParticipantsDataExtended } from '@/composables/useAiTabData.ts'
import { IMeterItem } from '@voicenter-team/voicenter-ui-plus'
import { useCallAiTabInject } from '@/composables/callAiTabProvideInject.ts'
import EmojiSvgIcon from '@/components/Icons/EmojiSvgIcon.vue'
import TagsWorker from '@/services/TagsWorker.ts'
import { getParsedMessageTimeFromSeconds } from '@/helpers/dateTime.helper.ts'
import { getTranslateWithDefault } from '@/helpers/translate.ts'
import { useI18n } from 'vue-i18n'

const { emotionClickHandler } = useCallAiTabInject()
const { t } = useI18n()

const props = withDefaults(
    defineProps<{
        data: ParticipantsDataExtended,
        color?: string,
        user: number,
        totalCallDuration: number,
        printState?: boolean
    }>(),
    {
        color: '#888F9D',
        speakerValues: undefined
    })
const emit = defineEmits([ 'emotion:click' ])

/* Computed */
const isRtl = computed(() => {
    return TagsWorker.isRtl
})
const speakerDataText = computed(() => {
    return `${getParsedMessageTimeFromSeconds(props.data.total_talking_time)} (${talkTimePercentage.value}%)`
})
const talkTimePercentage = computed(() => {
    return Math.round(props.data.total_talking_time * 100 / props.totalCallDuration)
})
const emotionsWithStrength = computed(() => {
    return getEmotionStrength(props.data.emotions).sort((a, b) => b.strength - a.strength)
})
const strongestEmotions = computed(() => {
    return emotionsWithStrength.value.slice(0, 5)
})
const emotionMetrics = computed<Array<IMeterItem>>(() => {
    const _data = strongestEmotions.value
    return _data.map((item, index) => {
        const nextItem = _data[index + 1] ?? item

        return {
            value: item.strength,
            label: item.emotion,
            color: getEmotionColor(item.emotion),
            color2: getEmotionColor(nextItem.emotion)
        }
    })
})

/* Methods */
function clickEmotion (data: string) {
    if (emotionClickHandler) {
        emotionClickHandler({
            type: data,
            label: data,
            user: props.user
        })
    }
    emit('emotion:click', {
        type: data,
        label: data,
        user: props.user
    })
}
</script>

<style lang="scss">
.speaker-emotions-card {
    &__title {
        @apply flex flex-col py-3 px-3 xl:px-4 border-b border-ui-lines gap-2 lg:gap-3 leading-[28px];
    }
    &__content {
        @apply py-3 px-3 xl:px-4;
    }
    .meter-group-data {
        @apply w-full;
    }
    .user-head-info {
        @apply flex gap-2 flex-wrap  items-center;
    }
    .user-head-info__title {
        @apply font-medium gap-2 flex items-center;
    }
    .user-head-info__data {
        @apply text-xs lg:text-sm;
    }
    .user-state-color {
        @apply flex-shrink-0 rounded-full text-sm lg:text-base;
        width: 8px;
        height: 8px;
    }
    .content-block-title {
        @apply text-xs uppercase text-placeholders font-semibold;
    }
    .emotion-name-item, .text-item {
        @apply text-xs capitalize font-medium;
    }
}
@container (min-width: 1700px) {
    .speaker-emotions-card {
        .speaker-emotions-card__title {
            @apply flex-row justify-between items-center;
        }
        .meter-group-data {
            @apply xl:w-[200px];
        }
    }
}
.speaker-emotions-card.is--printed {
    .speaker-emotions-card__title {
        @apply flex-col text-sm items-start;
    }
    .user-head-info__user, .user-head-info__data {
        @apply leading-none h-auto flex items-center text-sm;
    }

    .user-head-info__title, .user-head-info {
        @apply flex items-center leading-none text-sm;
    }
    .meter-group-data {
        @apply w-[200px];
    }
    .user-state-color {
        width: 8px;
        height: 8px;
    }
    .speaker-emotions-card__content {
        @apply text-xs py-0;
    }
    .emotion-name-item, .text-item, .content-block-title {
        @apply text-xs;
    }
    .min-w-44 {
        @apply min-w-36;
    }
}
</style>
