import { computed, ref } from 'vue'
import TagsWorker from '@/services/TagsWorker.ts'

export const isWidgetState = ref(false)
export const loadingUiState = ref(true)
export const teleportedPopupsState = ref(true)
export const usedWidgetShadowRootEl = ref<HTMLElement | undefined>(undefined)

export const currentGlobalLoadingState = computed(() => {
    return loadingUiState.value || TagsWorker.tagsLoading
})

export const isTeleported = computed(() => {
    return teleportedPopupsState.value
})
export function startGlobalLoading () {
    loadingUiState.value = true
}
export function stopGlobalLoading () {
    loadingUiState.value = false
}

export function setTeleportedPopupsState (state: boolean) {
    teleportedPopupsState.value = state
}
export function setWidgetState (state: boolean) {
    isWidgetState.value = state
}

export function stopGlobalLoadingByDelay (time: number) {
    setTimeout(() => {
        stopGlobalLoading()
    }, time)
}
