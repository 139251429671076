<template>
    <div class="w-full lead-info-tab">
        <div class="block-title mb-3 lg:mb-6">
            {{ t('callHistoryView.leadInfo.title') }}
        </div>
        <div class="lead-info-tab__content">
            <VcTable
                :columns="leadInfoTableColumns"
                :options="webTrackerData"
                with-pagination
                compact
                stripe
            >
                <template #referrerUrl-row="{row}">
                    <div class="">
                        {{ row.referrerUrl ?? '---' }}
                    </div>
                </template>
                <template #query-row="{ row }">
                    <div class="flex-center gap-2 w-full">
                        <VcPopover
                            :teleported="isTeleported"
                            trigger="hover"
                            :disabled="!Object.entries(row.query || {}).length"
                            popover-max-width="460"
                        >
                            <template #reference>
                                <i class="vc-lc-info icon-lg text-active-elements" />
                            </template>
                            <div class="p-3 grid grid-cols-[auto_1fr] gap-2">
                                <div
                                    v-for="(item, i) in Object.entries(row.query || {})"
                                    :key="i"
                                    class="items-center justify-between mb-2 grid"
                                    style="grid-template-columns: subgrid; grid-column: 1 / 3 "
                                >
                                    <div class="font-semibold">
                                        {{ item[0] }}:
                                    </div>
                                    <div class="break-all">
                                        {{ item[1] }}
                                    </div>
                                </div>
                            </div>
                        </VcPopover>
                    </div>
                </template>
                <template #userAgent-row="{ row }">
                    <div class="flex-center gap-2 w-full">
                        <VcPopover
                            v-if="Object.entries(row.userAgent || {}).length"
                            :teleported="isTeleported"
                            trigger="hover"
                            popover-max-width="460"
                        >
                            <template #reference>
                                <i class="vc-lc-info icon-lg text-active-elements" />
                            </template>
                            <div class="p-3 text-xs">
                                <div
                                    v-for="(item, i) in Object.entries(row.userAgent || {})"
                                    :key="i"
                                    class="grid grid-cols-[100px_1fr] gap-x-1 gap-y-2"
                                >
                                    <span class="font-semibold">{{ item[0] }}:</span>
                                    <span>{{ item[1] }}</span>
                                </div>
                            </div>
                        </VcPopover>
                        <div v-else>
                            ---
                        </div>
                    </div>
                </template>
                <template #currentPage-row="{ row }">
                    <div class="flex items-center gap-3">
                        <a
                            :href="row.currentPage"
                            target="_blank"
                            class="flex items-center gap-2"
                            :title="row.currentPage"
                        >
                            <span class="hover:underline">{{ getSiteName(row.currentPage) }}</span> <i class="vc-lc-external-link icon-base text-active-elements" />
                        </a>
                        <i
                            title="Copy"
                            class="vc-lc-copy text-active-elements hover:text-active-elements--focus"
                            @click="copyLink(row.currentPage || '')"
                        />
                    </div>
                </template>
            </VcTable>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import leadInfoTableColumns from '@/enums/tables/leadInfo.table.enum'
import useNotifyService from '@/composables/useNotifyService.ts'
import { webTrackerData } from '@/composables/useCDRData.ts'
import { isTeleported } from '@/services/GlobalState.ts'

const { t } = useI18n()

function getSiteName (url: string = '') {
    return new URL(url).hostname
}
function copyLink (url: string) {
    navigator?.clipboard?.writeText(url)
        .then(() => {
            useNotifyService.add({
                type: 'success',
                title: 'Copy',
                message: 'URL was copied successfully!',
                group: 'bottom-right'
            })
        })
}
</script>
