import { ColumnType } from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'

const { t } = i18n.global

const leadInfoTableColumns: ColumnType = [
    {
        columnItemKey: 'time-column',
        rowItemKeyKey: 'time-row',
        rowItemLabelKey: 'time',
        columnItemLabel: t('callHistoryView.leadInfo.time'),
        sortBy: 'time',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'query-column',
        rowItemKeyKey: 'query-row',
        rowItemLabelKey: 'query',
        columnItemLabel: t('callHistoryView.leadInfo.query'),
        sortBy: 'query',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'siteDomain-column',
        rowItemKeyKey: 'siteDomain-row',
        rowItemLabelKey: 'siteDomain',
        columnItemLabel: t('callHistoryView.leadInfo.siteDomain'),
        sortBy: 'siteDomain',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'currentPage-column',
        rowItemKeyKey: 'currentPage-row',
        rowItemLabelKey: 'currentPage',
        columnItemLabel: t('callHistoryView.leadInfo.currentPage'),
        sortBy: 'currentPage',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'referrerUrl-column',
        rowItemKeyKey: 'referrerUrl-row',
        rowItemLabelKey: 'referrerUrl',
        columnItemLabel: t('callHistoryView.leadInfo.referrerUrl'),
        sortBy: 'referrerUrl',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    },
    {
        columnItemKey: 'userAgent-column',
        rowItemKeyKey: 'userAgent-row',
        rowItemLabelKey: 'userAgent',
        columnItemLabel: t('callHistoryView.leadInfo.userAgent'),
        sortBy: 'userAgent',
        columnAlignment: 'left',
        rowItemAlignment: 'left'
    }
]

export default leadInfoTableColumns

/*
* {
  "query": {},
  "userAgent": {
    "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
    "language": "he-IL",
    "platform": "Windows",
    "vendor": "Google Inc.",
    "brands": [
      {
        "brand": "Not/A)Brand",
        "version": "8"
      },
      {
        "brand": "Chromium",
        "version": "126"
      },
      {
        "brand": "Google Chrome",
        "version": "126"
      }
    ],
    "mobile": false
  },
  "time": "2024-06-27T15:39:35.969Z",
  "siteDomain": "develop.mirrored-voicenter-website-he.pages.dev",
  "currentPage": "https://develop.mirrored-voicenter-website-he.pages.dev/",
  "referrerUrl": "https://www.google.com/"
}
* */
