import { getCDRColorByKey } from '@/composables/useColorsComposable.ts'

export const CDR_TYPE_SET = {
    INCOMING: 1, //	A regular incoming call that was dialed to a phone number and ended up in the IVR or an extension (did not end in a queue, which will make it type 4).
    CC: 2, // A call that was made through a calling card (Access number) service.
    EXTENSION_OUTGOING: 4, // A regular outgoing call (manually dialed from the phone).
    QUEUE: 8, // An incoming call that was received by a queue.
    CLICK_2_CALL_LEG_1: 9, // A call that was made by click2call (Not by Dialer). Leg 1 of the call. Leg 1 - the initial connection of the call to the extension. This is the “Phone” parameter in the Click2call API.
    CLICK_2_CALL_LEG_2: 10, // A call that was made by click2call (Not by Dialer). Leg 2 of the call. Leg 2 - the actual call that is being made to the destination. This is the “Target” parameter in the Click2call API.
    VOICE_MAIL: 11, // A call that was answered by Voicenter voicemail.
    CALLFERENCE: 12, //	A call that was made through Voicenter callference service.
    XFER_CDR: 13, // A call that manually transferred from an extension to a DID/another extension.
    PRODUCTIVE_CALL_LEG_1: 14, // A "leg 1" Agents Auto Dialer calls. Leg1 - the initial connection of the call to the extension.
    PRODUCTIVE_CALL_LEG_2: 15, // A "leg 2" Agents Auto Dialer calls. Leg 2 -the actual call that is being made to the destination.
    FAX_CDR: 16, // A call that was made through Voicenter's internal outgoing fax service. *Not released yet.
    SCRUBBER: 15, // A call that was made through Voicenter's Scrubber service.
    CLICK_2_IVR: 17, // "Leg1" Predictive Dialer calls. Leg1 - the initial connection of the call to the destination.
    CLICK_2_IVR_INCOMING: 18, // This is the "Leg 2" Predictive Dialer call. After the initial Leg1 (Click 2 IVR) call was answered, this new leg is dialing the IVR (a layer in Voicenter telephony menu) and Leg 1 is connected to the representatives. This call type is for all Click 2 IVR that did not enter a queue. If the call enters a queue service the type will be type 15.
    CLICK_2_QUEUE_INCOMING: 19, // This is the "Leg 2" Predictive Dialer call. After the initial Leg1 (Click 2 IVR) call was answered, this new leg is dialing the IVR (a layer in Voicenter telephony menu) and Leg 1 is connected to the representatives. If the call enters a queue, then the call status will be - Click 2 Queue. Else, the call type will be type 14.
    ATTENDED_CDR_LEG_1: 21, // A call that was transferred with consultation.
    ATTENDED_CDR_LEG_2: 22, // A call that was transferred with consultation. This type will only be made in a case of an incoming call that was answered by representative "A", "A" will then put the call on hold and make another call(consult) to another person - "B". Afterward, "A" will transfer the initial call to "B". The "Leg 2" is the part of the call between "B" and the initial caller.
    AUTO_FORWARD: 23, // A call that was automatically transferred from an extension to a DID (usually representatives configure their phones manually to transfer calls when they are not available).
} as const

export default {
    [CDR_TYPE_SET.INCOMING]: {
        name: 'Incoming Call',
        icon: 'phone-incoming',
        color: getCDRColorByKey('INCOMING')
    },
    [CDR_TYPE_SET.CC]: {
        name: 'CC',
        icon: 'call-card-cc',
        color: getCDRColorByKey('CC')
    },
    3: {
        name: 'Unknown',
        icon: 'default',
        color: getCDRColorByKey('UNKNOWN')
    },
    [CDR_TYPE_SET.EXTENSION_OUTGOING]: {
        name: 'Extension Outgoing',
        icon: 'phone-outgoing',
        color: getCDRColorByKey('EXTENSION_OUTGOING')
    },
    5: {
        name: 'Unknown',
        icon: 'default',
        color: getCDRColorByKey('UNKNOWN')
    },
    6: {
        name: 'Unknown',
        icon: 'default',
        color: getCDRColorByKey('UNKNOWN')
    },
    7: {
        name: 'Unknown',
        icon: 'default',
        color: getCDRColorByKey('UNKNOWN')
    },
    [CDR_TYPE_SET.QUEUE]: {
        name: 'Queue',
        icon: 'users-queue',
        color: getCDRColorByKey('QUEUE')
    },
    [CDR_TYPE_SET.CLICK_2_CALL_LEG_1]: {
        name: 'Click2Call leg1',
        icon: 'click-to-call',
        color: getCDRColorByKey('CLICK_2_CALL_LEG_1')
    },
    [CDR_TYPE_SET.CLICK_2_CALL_LEG_2]: {
        name: 'Click2Call leg2',
        icon: 'click-to-call-check',
        color: getCDRColorByKey('CLICK_2_CALL_LEG_2')
    },
    [CDR_TYPE_SET.VOICE_MAIL]: {
        name: 'VoiceMail',
        icon: 'voicemail',
        color: getCDRColorByKey('VOICE_MAIL')
    },
    [CDR_TYPE_SET.CALLFERENCE]: {
        name: 'Callference',
        icon: 'default',
        color: getCDRColorByKey('CALLFERENCE')
    },
    [CDR_TYPE_SET.XFER_CDR]: {
        name: 'XferCDR',
        icon: 'xferCDR',
        color: getCDRColorByKey('XFER_CDR')
    },
    [CDR_TYPE_SET.PRODUCTIVE_CALL_LEG_1]: {
        name: 'ProductiveCall Leg1',
        icon: 'default',
        color: getCDRColorByKey('PRODUCTIVE_CALL_LEG_1')
    },
    [CDR_TYPE_SET.PRODUCTIVE_CALL_LEG_2]: {
        name: 'ProductiveCall Leg2',
        icon: 'productive-call-check',
        color: getCDRColorByKey('PRODUCTIVE_CALL_LEG_2')
    },
    [CDR_TYPE_SET.CLICK_2_IVR]: {
        name: 'Click 2 IVR',
        icon: 'default',
        color: getCDRColorByKey('CLICK_2_IVR')
    },
    [CDR_TYPE_SET.CLICK_2_IVR_INCOMING]: {
        name: 'Click 2 IVR Incoming',
        icon: 'default',
        color: getCDRColorByKey('CLICK_2_IVR_INCOMING')
    },
    [CDR_TYPE_SET.CLICK_2_QUEUE_INCOMING]: {
        name: 'Click 2 Queue Incoming',
        icon: 'default',
        color: getCDRColorByKey('CLICK_2_QUEUE_INCOMING')
    },
    [CDR_TYPE_SET.FAX_CDR]: {
        name: 'FaxCdr',
        icon: 'default',
        color: getCDRColorByKey('FAX_CDR')
    },
    [CDR_TYPE_SET.ATTENDED_CDR_LEG_1]: {
        name: 'Attended CDR leg1',
        icon: 'default',
        color: getCDRColorByKey('ATTENDED_CDR_LEG_1')
    },
    [CDR_TYPE_SET.ATTENDED_CDR_LEG_2]: {
        name: 'Attended CDR leg2',
        icon: 'default',
        color: getCDRColorByKey('ATTENDED_CDR_LEG_2')
    },
    [CDR_TYPE_SET.AUTO_FORWARD]: {
        name: 'Auto forward',
        icon: 'default',
        color: getCDRColorByKey('AUTO_FORWARD')
    },
    0: {
        name: 'Unknown',
        icon: 'default',
        color: getCDRColorByKey('UNKNOWN')
    }
} as Record<number, { name: string, icon: string, color: string }>
