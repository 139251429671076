import { computed, getCurrentInstance, warn } from 'vue'
import { TWidgetOptions } from '@/types/widget'
import { TUIColorName } from '@/types/colors'

export type TRoot = HTMLElement & {
    initData?: (options: TWidgetOptions) => void
    defineTheme?: (theme?: Record<TUIColorName, string>) => void
    initWidget?: () => void
}
// NOTE: Since it depends on Vue internals, it's likely to break when the vnode
// implementation changes.
export function useShadowHost () {
    const instance = getCurrentInstance()

    return computed(() => {
        try {
            return instance!.vnode!.el!.parentNode.host as TRoot
        } catch (error) {
            warn('component instance or element not available, can not find shadow host', instance)
            return undefined
        }
    })
}
