<template>
    <div
        class="cdr-type-svg-icon"
        :style="styles"
        :title="name"
        v-html="svg"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { convertToUnit } from '@/helpers/general.helper.ts'

const props = defineProps<{
    name: string,
    size?: string | number
    color?: string
}>()

const svgContent = import.meta.glob('./cdr/*.svg', {
    as: 'raw',
    eager: true,
})[`./cdr/${props.name}.svg`]

const styles = computed(() => {
    return {
        width: convertToUnit(props.size ?? 24),
        height: convertToUnit(props.size ?? 24),
        color: props.color
    }
})
const svg = computed(() => {
    return svgContent
})
</script>

<style lang="scss">
.cdr-type-svg-icon {
    @apply inline-flex items-center justify-center max-w-full max-h-full overflow-hidden;
    svg {
        @apply w-full h-full;
    }
}
</style>
