<template>
    <div class="content-ellipse-card">
        <div class="content-ellipse-card__content-title">
            <div class="block-title">
                {{ title }}
                <span
                    v-if="question.question"
                    class="block-help-action"
                >
                    <VcPopover
                        :teleported="isTeleported"
                        trigger="hover"
                        placement="top"
                        popover-max-width="320px"
                    >
                        <template #reference>
                            <i class="vc-lc-circle-help cursor-pointer" />
                        </template>
                        <div class="p-2 lg:p-3 text-center text-sm text-balance">
                            {{ question.question }}
                        </div>
                    </VcPopover>
                </span>
            </div>
            <div>
                <div class="content-ellipse-card__arrow w-6">
                    <span
                        v-if="hasMoreContent"
                        class="cursor-pointer"
                        @click="isOpen = !isOpen"
                    >
                        <i
                            :class="{ 'rotate-180': isOpen }"
                            class="vc-lc-chevron-down icon-xl transition-all duration-300"
                        />
                    </span>
                </div>
            </div>
        </div>
        <div
            ref="blockContentWrapRef"
            class="content-ellipse-card__content"
        >
            <div
                ref="blockContentRef"
                :class="isOpen ? 'is--more' : 'is--less'"
                class="block-content"
            >
                <div v-if="isContentString">
                    {{ content }}
                </div>
                <div v-if="isContentBoolean">
                    <span
                        v-if="content"
                        class="vc-lc-square-check-big icon-lg text-success"
                    />
                    <span
                        v-else
                        class="vc-lc-square-x icon-lg text-error"
                    />
                </div>
                <div v-if="isContentArray">
                    <ul class="md:list-disc md:px-4 space-y-2">
                        <li
                            v-for="(item, j) in content"
                            :key="`i-${j}`"
                        >
                            {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import { Question } from '@/types/api'
import { isTeleported } from '@/services/GlobalState.ts'

const { question } = defineProps<{
    question: Question
}>()

const blockContentWrapRef = ref<HTMLElement>()
const blockContentRef = ref<HTMLElement>()

const hasMoreContent = ref<boolean>(false)
const isOpen = ref<boolean>(true)

const title = computed(() => {
    return question.key.replaceAll('_', ' ')
})
const content = computed(() => {
    return safeJSONParse(question.answer)
})
const isContentString = computed(() => {
    return typeof content.value === 'string' || typeof content.value === 'number'
})
const isContentBoolean = computed(() => {
    return typeof content.value === 'boolean'
})
const isContentArray = computed(() => {
    return Array.isArray(content.value)
})
// const isDisableExpand = computed(() => {
//     return !isContentString.value
// })

/* Methods */
function init () {
    nextTick(() => {
        if (!blockContentRef.value || !blockContentWrapRef.value) {
            return
        }
        const { height = 0 } = blockContentWrapRef.value.getBoundingClientRect()

        hasMoreContent.value = height > 40 || isContentArray.value && content.value.length > 1
        isOpen.value = false
    })
}
function safeJSONParse (jsonString: string | number | boolean) {
    if (typeof jsonString !== 'string') {
        return jsonString
    }
    if (!jsonString) {
        return ''
    }
    try {
        if (!jsonString.startsWith('[') || !jsonString.startsWith('{')) {
            return JSON.parse(jsonString)
        }
    } catch (e) {
        return jsonString
    }
}

onMounted(() => {
    init()
})

defineExpose({
    expand: () => isOpen.value = true,
    collapse: () => isOpen.value = false
})
</script>

<style lang="scss">
.content-ellipse-card {
    @apply w-full border border-ui-lines rounded-lg p-2 md:p-4;

    &__content-title {
        @apply w-full flex justify-between items-center gap-4;
    }

    .block-title {
        @apply text-base font-semibold pb-3;
    }

    &__content {
        width: calc(100% - 64px);
    }

    .block-help-action {
        @apply text-placeholders;
    }

    .block-content {
        @apply text-sm;
        transition: var(--base-transition);
        line-height: 20px;
    }

    .block-content.is--less {
        width: max-content;
        max-width: 100%;
        word-wrap: break-word;
        //white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .block-content.is--more {
        white-space: normal;
        height: auto;
    }

    &__arrow span {
        @apply flex justify-center items-center;
    }
}
</style>
