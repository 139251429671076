<template>
    <div class="call-history-ai-tab">
        <div v-if="!hasAiData">
            <div class="w-full rounded bg-inactive-elements-bg--focus flex-center py-5">
                <div class="font-medium text-base">
                    {{ t('callHistoryView.noData') }}
                </div>
            </div>
        </div>
        <div
            v-else
            class="ai-tab__content"
        >
            <div class="ai-tab__summary">
                <CallAISatisfactionBlock
                    :agent-data="callersData?.agent"
                    :client-data="callersData?.client"
                />
            </div>
            <div class="ai-tab__chart">
                <CallAiChartDataBlock
                    ref="callAiChartDataRef"
                    @update:time="onUpdateSoundTime"
                />
            </div>
            <div class="ai-tab__main">
                <MainSummaryBlock>
                    {{ aiInsightsSummary }}
                </MainSummaryBlock>
                <EmotionsPolarChart
                    ref="emotionsPolarChartRef"
                />
                <MainKeywordsBlock
                    :keywords-speaker0="keywordsMapped.keywordsSpeaker0"
                    :keywords-speaker1="keywordsMapped.keywordsSpeaker1"
                />
            </div>
            <div
                v-if="aiInsights?.questions?.length"
                class="ai-tab__key-points"
            >
                <MainKeyPointsBlock
                    :questions="aiInsights?.questions"
                />
            </div>
            <div class="ai-tab__transcript">
                <CallAiTranscriptionBlock
                    ref="transcriptionBlockRef"
                    @click:message="setSoundTime"
                />
            </div>
            <div
                v-if="isUserAdmin"
                class="ai-tab__footer"
            >
                <div
                    class="uppercase text-sm font-semibold text-placeholders flex items-center"
                >
                    {{ t('callHistoryView.ai.debugInfo') }}
                    <VcPopover
                        :teleported="isTeleported"
                        placement="top"
                        trigger="hover"
                        popover-max-width="420px"
                    >
                        <template #reference>
                            <span class="inline-flex px-1 items-center">
                                <i class="vc-lc-info cursor-pointer" />
                            </span>
                        </template>
                        <div
                            class="p-3 text-xs max-h-[460px] overflow-y-auto"
                            dir="ltr"
                        >
                            <pre>{{ debugAiInfo }}</pre>
                        </div>
                    </VcPopover>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { aiInsightsSummary, debugAiInfo, hasAiData } from '@/composables/useAIData.ts'
import { isUserAdmin } from '@/composables/useJWTPayloadData.ts'
import CallAISatisfactionBlock from '@/components/Blocks/AI/CallAISatisfactionBlock.vue'
import { aiInsights, callersData, keywordsMapped } from '@/composables/useAiTabData.ts'
import { useCallAiTabProvide } from '@/composables/callAiTabProvideInject.ts'
import { TEmotionCallerClickData, TKeyWordsSpeakerMapped, TMessageItem } from '@/types/types'
import CallAiChartDataBlock from '@/components/Blocks/AI/CallAiChartDataBlock.vue'
import MainKeywordsBlock from '@/components/Blocks/AI/MainKeywordsBlock.vue'
import MainSummaryBlock from '@/components/Blocks/AI/MainSummaryBlock.vue'
import MainKeyPointsBlock from '@/components/Blocks/AI/MainKeyPointsBlock.vue'
import CallAiTranscriptionBlock from '@/components/Blocks/AI/CallAiTranscriptionBlock.vue'
import { isTeleported } from '@/services/GlobalState.ts'
import EmotionsPolarChart from '@/components/Chart/EmotionsPolarChart.vue'

const { t } = useI18n()

/* Data */
const callAiChartDataRef = ref<typeof CallAiChartDataBlock>()
const transcriptionBlockRef = ref<typeof CallAiTranscriptionBlock>()
const emotionsPolarChartRef = ref<typeof EmotionsPolarChart>()

/* Methods */
function onUpdateSoundTime (time: number) {
    transcriptionBlockRef.value?.scrollToMessage(time)
    emotionsPolarChartRef.value?.setTime(time)
}
function wordClickHandler (data: TKeyWordsSpeakerMapped) {
    transcriptionBlockRef.value?.setSearchData(data.name)
}
function setSoundTime (message: TMessageItem) {
    callAiChartDataRef.value?.setTime(message.startTime)
}
function onEmotionClick (data: TEmotionCallerClickData) {
    callAiChartDataRef.value?.highlightEmotionsByUser(data)
}

useCallAiTabProvide({
    wordCloudItemClick: wordClickHandler,
    emotionClickHandler: onEmotionClick
})
</script>

<style lang="scss">
.call-history-ai-tab {
    .ai-tab__content {
        @apply grid rounded-lg border border-ui-lines mb-5 overflow-hidden;
        grid-template-columns: minmax(0, 1fr) min(40%, 548px);
        grid-template-areas:
            "summary transcript"
            "chart transcript"
            "main transcript"
            "keypoints keypoints"
            "footer footer";
    }
    .ai-tab__summary {
        @apply border-b border-b-ui-lines h-max;
        grid-area: summary;
    }
    .ai-tab__chart {
        @apply border-b border-b-ui-lines h-max;
        grid-area: chart;
    }
    .ai-tab__transcript {
        @apply border-b border-l border-ui-lines relative pb-3 max-w-full;
        grid-area: transcript;
        min-width: 548px;
    }
    .ai-tab__main {
        @apply border-b border-b-ui-lines h-max p-3 lg:p-5 xl:p-8 flex flex-col gap-3 lg:gap-6;
        grid-area: main;
    }
    .ai-tab__key-points {
        @apply py-3 px-3 lg:pt-5 lg:px-5 xl:py-8 xl:px-8;
        grid-area: keypoints;
    }
    .ai-tab__insights {
        @apply px-3 lg:px-5 xl:px-8;
        grid-area: insights;
    }
    .ai-tab__footer {
        @apply border-t border-ui-lines p-3 lg:p-5;
        grid-area: footer;
    }
    .call-history-transcription {
        @apply inset-x-0 top-0 bottom-2;
        position: absolute;
    }
}
@container (max-width: 1264px) {
    .call-history-ai-tab {
        .ai-tab__content {
            grid-template-columns: 1fr;
            grid-template-areas:
                        "summary"
                        "chart"
                        "transcript"
                        "main"
                        "keypoints"
                        "footer";
        }
        .ai-tab__transcript {
            @apply border-l-0;
        }
        .call-history-transcription {
            position: relative;
            max-height: 480px;
        }

        .ai-tab__transcript {
            min-width: 100%;
        }
    }
}
</style>
