import { inject, provide } from 'vue'
import { TabAiDataTypeProvideToken, TabAiProvideToken, TAiTabOptionsProvide } from '@/tokens'

export function useCallAiTabProvide (options: TAiTabOptionsProvide) {
    provide(TabAiProvideToken, options)
}

export function useCallAiTabInject () {
    return inject<Partial<TAiTabOptionsProvide>>(TabAiProvideToken, {})
}

export const API_ONLY_DATA_TYPE = 'apiOnly'
export const API_DEFAULT_DATA_TYPE = 'default'

export function useCallAiDataTypeProvide (type: string) {
    provide(TabAiDataTypeProvideToken, {
        type,
        isApiOnly: type === API_ONLY_DATA_TYPE
    })
}

export function useCallAiDataTypeInject () {
    return inject<{type: string,isApiOnly: boolean }>(TabAiDataTypeProvideToken, {
        type: API_DEFAULT_DATA_TYPE,
        isApiOnly: false
    })
}
