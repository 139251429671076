import { computed, ref } from 'vue'
import { CdrData } from '@/types/api'
import { ObjectAnyType } from '@/types/types'
import { convertStringToObject } from '@/helpers/object.helper.ts'
import FormsStorage from '@/services/FormsStorage.ts'
import { FORM_DATA_LAST_UPDATED_KEY } from '@/composables/useCallForms.ts'
import isAfter from 'date-fns/isAfter'
import { getCallMainData } from '@/helpers/apiData.helper.ts'

export const CdrDataRef = ref<CdrData | undefined>(undefined)
export const technicalDataRef = ref<ObjectAnyType | undefined>(undefined)

/* Computed */
export const generalCallData = computed(() => {
    const _cdrData = CdrDataRef.value
    return getCallMainData(_cdrData)
})
export const webTrackerData = computed(() => {
    return CdrDataRef.value?.WebTrackerData || []
})
export const isLeadInfoTabVisible = computed(() => {
    return !!webTrackerData.value?.length
})
export const ivrData = computed(() => {
    return CdrDataRef.value?.dtmfData?.layers || []
})
export const hasIvrData = computed(() => {
    return !!ivrData.value.length
})
export const hasDialerData = computed(() => {
    return !!CdrDataRef.value?.dialerData
})

/* Methods */
export async function setCdrData (data?: CdrData) {
    CdrDataRef.value = data
    const callVars = data?.callVars || ''
    technicalDataRef.value = convertStringToObject(callVars)
    await initTechnicalData(callVars)
}
export function getCdrData () {
    return CdrDataRef.value
}
export function getDiallerData () {
    return CdrDataRef.value?.dialerData
}
export function setTechnicalData (data: ObjectAnyType, formId: string) {
    if (!technicalDataRef.value) {
        technicalDataRef.value = { Forms: {} }
    }
    FormsStorage.value[formId] = { ...data }
    technicalDataRef.value.Forms[formId] = { ...data }
}

function initTechnicalData (callVars: string) {
    return new Promise(resolve => {
        const techData = convertStringToObject(callVars)
        if (!techData.Forms || !techData.Forms.length) {
            resolve(true)
        } else {
            Object.keys(techData.Forms)?.forEach(item => {
                if (FormsStorage.value[item]) {
                    const storageDate = FormsStorage.value[item][FORM_DATA_LAST_UPDATED_KEY]
                    const techDate = techData.Forms[item][FORM_DATA_LAST_UPDATED_KEY]
                    if (isAfter(storageDate, techDate)) {
                        techData.Forms[item] = { ...FormsStorage.value[item] }
                    }
                }
            })

            resolve(true)
        }
    })
}
