import { activitySentences, getEmotionValue } from '@/composables/useAiTabData.ts'
import { AGENT_SPEAKER, CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { getSvgBase64Icon } from '@voicenter-team/emotion-icons'
import { computed } from 'vue'
import { LegendComponentOption } from 'echarts'
import { recordingDuration } from '@/composables/useCallRecording.ts'

type LegendComponentOptionExtended = LegendComponentOption & {
    shadowColor?: string
    shadowBlur?: number
}

const _lineSeriesData = computed(() => {

    const _messages = [ ...activitySentences.value ].reduce(
        (acc, el) => {

            if (el.message) {
                const start = el.message.startTime
                const rate = getEmotionValue(el)
                const speakerName = el.speakerName
                const text = el.message.text
                const icon = `image://${getSvgBase64Icon(el.emotion)}`
                // const end = message.endTime
                const emotion = el.emotion

                /*
                 * [
                 *      0 - datetime,
                 *      1 - rate value,
                 *      2 - speaker name,
                 *      3 - message,
                 *      4 - icon,
                 *      5 - emotion,
                 *      6 - sentence_id
                 * ]
                 */
                const data = [ start, rate, speakerName, text, icon, emotion, el.message.sentence_id ]

                if (el.speaker === AGENT_SPEAKER) {
                    acc.series1.push(data)
                } else if (el.speaker === CALLER_SPEAKER) {
                    acc.series2.push(data)
                }
            }

            return acc
        },
        {
            series1: [],
            series2: []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
    )
    _messages.series1 = insertFirstAndLastMessages(_messages.series1)
    _messages.series2 = insertFirstAndLastMessages(_messages.series2)
    return _messages
})
function insertFirstAndLastMessages (dataToInsert: Array<Array<string | number>>) {
    const firstMessage = dataToInsert[0]
    const lastMessage = dataToInsert[0]
    if (+firstMessage[0] > 0) {
        dataToInsert.unshift([ 0, 0, '', '', '', '', -1 ])
    }
    if (+lastMessage[0] < +recordingDuration.value) {
        dataToInsert.push([ +recordingDuration.value, 0, '', '', '', '', -1 ])
    }
    return [ ...dataToInsert ]
}

const defaultLegendOptions: LegendComponentOptionExtended = {
    height: '32px',
    name: 'legend',
    orient: 'horizontal',
    left: 'left',
    top: 'top',
    icon: 'circle',
    itemHeight: 10,
    itemWidth: 10,
    textStyle: {
        lineHeight: 20,
        fontWeight: 500,
        fontSize: '12px',
        color: 'var(--default-text, black)'
    },
    selectedMode: 'multiple',
    backgroundColor: 'var(--light-bg, white)',
    borderRadius: [ 0, 0, 6, 0 ],
    shadowColor: 'var(--overlay-bg)',
    shadowBlur: 6
}

const defaultGridOptions = {
    left: '30px',
    right: '30px',
    bottom: '4px',
    top: '28px',
    containLabel: true
}

export default function () {
    return {
        SeriesData: _lineSeriesData,
        defaultLegendOptions,
        defaultGridOptions
    }
}
