<template>
    <CollapseBlock
        :title="t('callHistoryView.ai.summaryHeader')"
    >
        <div class="max-w-[800px] text-balance">
            <slot />
        </div>
    </CollapseBlock>
</template>

<script setup lang="ts">
import CollapseBlock from '@/components/Common/CollapseBlock.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

