import { FlatObjectType, ObjectAnyType, RecObjType } from '@/types/types'

export function traverseAndFlatten <T> (currentNode: RecObjType, target: FlatObjectType<T>, flattenedKey?: string) {
    for (const key in currentNode) {
        if (key in currentNode) {
            let newKey

            if (flattenedKey === undefined) {
                newKey = key
            } else {
                newKey = flattenedKey + '.' + key
            }

            const value = currentNode[key]

            if (typeof value === 'object') {
                traverseAndFlatten(value, target, newKey)
            } else {
                target[newKey] = value as T
            }
        }
    }
}

export function flatten <T> (obj: RecObjType) {
    const flattenedObject: FlatObjectType<T> = {}

    traverseAndFlatten(obj, flattenedObject)

    return flattenedObject
}

export function mergeFlatObjects <T> (source: FlatObjectType<T>, target: FlatObjectType<T>) {
    const result: FlatObjectType<T> = {}

    const keys = [ ...new Set([ ...Object.keys(source), ...Object.keys(target) ]) ]

    for (const key of keys) {
        const val1 = source[key]
        const val2 = target[key]

        result[key] = val1 ?? val2
    }

    return result
}

export function objectKeysOnlyStrings (object: ObjectAnyType) {
    const result = {} as { [key: string]: string }

    for (const key in object) {
        if (typeof object[key] === 'string') {
            result[key] = object[key]
        }
    }

    return result
}

export function convertStringToObject (str: string): ObjectAnyType {
    const obj: ObjectAnyType = {}
    if (!str) {
        return obj
    }
    str.split(',').forEach(pair => {
        const [ key, value ] = pair.split(':')
        const keys = key.split('.')

        let current = obj
        for (let i = 0; i < keys.length; i++) {
            if (i === keys.length - 1) {
                // if (keys[i].startsWith('MISelect') || `${value}`.startsWith('[')) {
                //     current[keys[i]] = JSON.parse(value)
                // } else {
                //     current[keys[i]] = isNaN(+value) ? value : Number(value)
                // }
                current[keys[i]] = parseFormDataObjectDataTypes(value)
                // Convert value to number if it's a number, otherwise keep it as a string

            } else {
                current[keys[i]] = current[keys[i]] || {}
                current = current[keys[i]]
            }
        }
    })
    return obj as ObjectAnyType
}

export const parseFormDataObjectDataTypes = (value: unknown): string | number | boolean | object | Record<string, string> => {
    const dateRegex = /([0-9]{4}-[0-9]{2}-[0-9]{2})T/gm

    if (typeof value === 'string') {
        if (value === 'true' || value === 'false') {
            return value === 'true'
        } else if (!isNaN(Number(value))) {
            return Number(value)
        } else {
            const exec = dateRegex.exec(value)

            if (exec === null) {
                try {
                    return JSON.parse(value)
                } catch (e) {
                    return value
                }
            } else {
                const group = exec[1]

                return `${group}T00:00:00.000Z`
            }
        }
    } else if (typeof value === 'object' && value !== null) {
        return parseFormDataObjectDataTypes(value)
    } else {
        return value as number | boolean
    }
}
