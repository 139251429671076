<template>
    <div class="billing-logs-tab">
        Billing Logs
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
