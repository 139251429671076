import { computed, ref } from 'vue'
import {
    AiDecryptionData as TAiDecryptionData,
    EncryptedData,
    RecordDecryptionData as TRecordDecryptionData
} from '@/types/api'

export const recordDecryptionData = ref<TRecordDecryptionData | undefined>(undefined)
export const aiDecryptionData = ref<TAiDecryptionData | undefined>(undefined)

export const hasRecordDecryptionData = computed(() => {
    return !!recordDecryptionData.value
})
export const hasAIDecryptionData = computed(() => {
    return !!aiDecryptionData.value
})

export function setEncryptedData (data?:  EncryptedData) {
    recordDecryptionData.value = data?.record_decryption_data
    aiDecryptionData.value = data?.ai_decryption_data
}
