<template>
    <VcCard class="call-ivr-flow-block py-3 lg:py-6 px-3 lg:px-8">
        <div class="call-ivr-flow-block__content">
            <div class="flex items-center gap-2">
                <span class="font-semibold text-base">
                    {{ t('callHistoryView.ivr.title') }}
                </span>
                <span class="inline-flex items-center">
                    <i class="vc-icon-info font-semibold text-icons icon-base cursor-pointer hover:text-active-elements--focus" />
                </span>
            </div>
            <div class="call-ivr-flow-block__flow-wrap pt-5 lg:pt-10">
                <div class="flex flex-wrap gap-x-2 gap-y-4">
                    <div
                        v-for="(ivrItem, index) in ivrData"
                        :key="`${ivrItem.ID}-${index}`"
                        class="call-ivr-item-block gap-x-2 gap-y-4"
                    >
                        <div
                            v-if="index"
                            class="call-ivr-item-action flex-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="2"
                                viewBox="0 0 18 2"
                                fill="none"
                                class="icon-before text-primary-actions"
                            >
                                <path
                                    d="M1 1H17"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div class="call-ivr-item is--action">
                                <div class="call-ivr-item__content min-w-20">
                                    <i class="vc-icon-speed-dial text-primary-actions" />
                                    {{ ivrItem.dTMF ?? ivrItem.prompt }}
                                </div>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="10"
                                viewBox="0 0 18 10"
                                class="icon-after text-primary-actions"
                                fill="none"
                            >
                                <path
                                    d="M1 5H17M17 5L12 1M17 5L12 9"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <VcPopover
                            :teleported="isTeleported"
                            trigger="hover"
                            placement="top"
                        >
                            <template #reference>
                                <div class="call-ivr-item is--menu">
                                    <div class="call-ivr-item__content">
                                        <div class="title">
                                            {{ ivrItem.name }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="p-3 text-center text-balance font-medium">
                                {{ ivrItem.number }}
                            </div>
                        </VcPopover>
                    </div>
                </div>
            </div>
        </div>
    </VcCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ivrData } from '@/composables/useCDRData.ts'
import { isTeleported } from '@/services/GlobalState.ts'

const { t } = useI18n()

</script>

<style lang="scss">
.call-ivr-flow-block {
    @apply relative;
    .call-ivr-item-block {
        @apply relative flex flex-wrap items-center;
    }
    .call-ivr-item {
        @apply relative cursor-pointer;
        &.is--action {
            @apply flex items-center justify-center;
            .call-ivr-item__content {
                @apply border border-primary-actions px-3 py-1 rounded-tag flex gap-2 items-center;
            }
        }
        &.is--menu {
            @apply shadow-card px-4 py-3 rounded-md;
            .title {
                @apply text-base font-medium;
            }
        }
    }
}
[dir='rtl'] {
    .call-ivr-flow-block {
        .icon-after {
            @apply rotate-180;
        }
    }
}
</style>
