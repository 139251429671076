import { ref } from 'vue'
import i18n from '@/plugins/i18n'
import ContentsService from '@/services/API/ContentsService'
import { flatten, mergeFlatObjects, objectKeysOnlyStrings } from '@/helpers/object.helper.ts'
import { isWidgetState } from '@/services/GlobalState.ts'

const DIRECTION_KEY = 'callHistoryView.styling.dir'
const WIDGET_DOMAIN = 'CALL-HISTORY-AI-WIDGET'

class TagsWorker {
    public tagsLoadingState = ref(false)
    direction = ref<string>('ltr')
    public rootComponentRef = ref<HTMLElement | undefined>(undefined)

    public get currentLanguageLocale (): string {
        /** TODO: get language he if needed */
        return 'en'
    }

    get tagsLoading () {
        return this.tagsLoadingState.value
    }

    get documentDirection () {
        return this.direction.value
    }

    get isRtl () {
        return this.documentDirection === 'rtl'
    }

    set tagsLoading (val: boolean) {
        this.tagsLoadingState.value = val
    }

    // get rootComponent () {
    //     return this.rootComponentRef.value || document?.documentElement
    // }
    set rootComponent (val: HTMLElement) {
        this.rootComponentRef.value = val
    }

    public async getLanguageTranslations (langId?: number) {
        const _domain = isWidgetState.value ? WIDGET_DOMAIN : ''
        const { Data } = await ContentsService.contentsTagsList(langId, _domain) as { Data: Record<string, string> }

        return objectKeysOnlyStrings(Data)
    }

    public setDocumentDirection (direction: string) {
        if (!this.rootComponentRef.value) {
            document?.documentElement?.setAttribute('dir', direction || 'ltr')
        } else {
            this.rootComponentRef.value.setAttribute('dir', direction || 'ltr')
            this.rootComponentRef.value.setAttribute('lang', this.currentLanguageLocale)
        }
        this.direction.value = direction || 'ltr'
    }

    public async setCurrentTranslations (langId?: number) {
        try {
            /** Get the remote translations  */
            const remoteLocaleMessages = await this.getLanguageTranslations(langId)
            //TODO: need to remove it
            if (import.meta.env.MODE === 'development') {
                const direction = import.meta.env.VITE_APP_LOCAL_DIRECTION || 'ltr'
                this.setDocumentDirection(direction)
            } else {
                this.setDocumentDirection(remoteLocaleMessages[DIRECTION_KEY])
            }

            const localLocaleMessages = i18n.global.getLocaleMessage(this.currentLanguageLocale) ?? {}
            const unflattenRemoteMessages = flatten<string>(remoteLocaleMessages)
            const resultMessages = mergeFlatObjects<string>(
                unflattenRemoteMessages,
                localLocaleMessages
            )

            i18n.global.locale.value = this.currentLanguageLocale

            // Set the merged locales
            i18n.global.setLocaleMessage(
                this.currentLanguageLocale,
                resultMessages
            )
        } catch (e) {
            console.error('Cannot set language:', e)
        }
    }

    public async init (languageID?: number) {
        try {
            this.tagsLoading = true
            await this.setCurrentTranslations(languageID)
        } finally {
            this.tagsLoading = false
        }
    }
}

export default new TagsWorker()
