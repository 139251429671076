import { CallAiOnlyHistoryData, CallHistoryData, CdrData } from '@/types/api'
import i18n from '@/plugins/i18n'
import { formatStringDate, getParsedMessageTimeFromSeconds } from '@/helpers/dateTime.helper.ts'
import { TMessageWithEmotionItem } from '@/types/types'
import { getTargetName } from '@/helpers/cdrData.helper.ts'

const DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss'

const { t } = i18n.global

function isApiOnlyDataType (apiData: CallHistoryData | CallAiOnlyHistoryData | undefined): apiData is CallHistoryData {
    return (apiData?.cdr_data?.sec_total !== undefined)
}
function hasCallMainData (cdrData: CdrData) {
    const _keys: Array<keyof CdrData> = [
        'cdr_time','dialstatus_name', 'caller_no',
        'target_no', 'iNC_extension_name', 'prefixTypeName', 'sec_total', 'provider'
    ]

    return _keys.some(key => cdrData[key])
}
export function getCallMainData (cdrData?: CdrData) {
    if (!cdrData || !hasCallMainData(cdrData)) {
        return []
    }
    return [
        {
            title: t('callHistoryView.mainData.dateTime'),
            content: formatStringDate(cdrData.cdr_time, DATE_FORMAT)
        },
        {
            title: t('callHistoryView.mainData.type'),
            content: cdrData.dialstatus_name,
        },
        {
            title: t('callHistoryView.mainData.callerNo'),
            content: cdrData.caller_no
        },
        {
            title: t('callHistoryView.mainData.destination'),
            content: getTargetName(cdrData)
        },
        {
            title: t('callHistoryView.mainData.prefix'),
            content: cdrData.prefixTypeName,
        },
        {
            title: t('callHistoryView.mainData.duration'),
            content: getParsedMessageTimeFromSeconds(cdrData.sec_total || 0)
        },
        {
            title: t('callHistoryView.mainData.amountDue'),
            content: '---'
        },
        {
            title: t('callHistoryView.mainData.provider'),
            content: cdrData.provider
        }
    ]
}

export function calcRecordingDuration (apiData: CallHistoryData | CallAiOnlyHistoryData | undefined) {
    if (isApiOnlyDataType(apiData)) {
        const secondsWaitingInQueue = apiData?.cdr_data?.SecondsWaitingInQueue || 0

        return (apiData?.cdr_data?.sec_total || 0) - secondsWaitingInQueue
    }
    return apiData?.ai_data?.total_time || 0
}
export function closestMessage (targetTime: number, messages: Array<TMessageWithEmotionItem>) {
    let closest = undefined
    let minDiff = Infinity

    messages.forEach(message => {
        const startTime = message.startTime
        const endTime = message.endTime

        if (endTime > targetTime && startTime <= targetTime) {
            const diff = Math.abs(startTime - targetTime)

            if (diff < minDiff) {
                minDiff = diff
                closest = message
            }
        }
    })

    return closest
}
