<template>
    <Teleport :to="teleportedTo">
        <transition
            name="slide-filter"
            appear
        >
            <div
                v-if="isPanelOpen"
                class="panel-preview-block"
                :style="{ zIndex: currentZIndex }"
            >
                <slot />
            </div>
        </transition>
    </Teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { ZIndexUtils } from '@voicenter-team/voicenter-ui-plus'
import { isWidgetState, usedWidgetShadowRootEl } from '@/services/GlobalState.ts'

/* Emits */
const emit = defineEmits([ 'close' ])
/* Data */
const isPanelOpen = ref<boolean>(false)

/* Computed */
const currentZIndex = computed(() => {
    return ZIndexUtils.getCurrent() + 1000
})
const teleportedTo = computed(() => {
    if (isWidgetState.value && !!usedWidgetShadowRootEl.value) {
        return usedWidgetShadowRootEl.value || 'body'
    }
    return 'body'
})
/* Methods */
const openPanel = () => {
    isPanelOpen.value = true
}
const closePanel = () => {
    isPanelOpen.value = false
    emit('close')
}

defineExpose({
    open: openPanel,
    close: closePanel
})
</script>

<style lang="scss">
    .panel-preview-block {
        @apply fixed right-0 top-0 bottom-0 bg-white;
        box-shadow: -6px 0 10px rgba(77, 78, 79, 0.6);
        width: min(100vw, 680px);
    }
</style>
