<template>
    <VcCard class="chart-activity-card">
        <div
            v-if="message"
            class="chart-activity-card__content"
        >
            <div
                v-if="message.emotion?.emotion"
                class="flex-center flex-shrink-0 pb-3"
            >
                <BaseEmojiGifIcon :emotion="message.emotion?.emotion" />
            </div>
            <div class="chart-activity-card__message">
                <div
                    class="font-medium leading-none flex-grow mb-3 break-all"
                    style="font-size: clamp(1.25rem, 2vw, 2.125rem)"
                >
                    {{ message.text }}
                </div>
                <div class="text-base font-medium">
                    {{ speakerName }}
                </div>
            </div>
            <div class="chart-activity-card__activities flex-shrink-0">
                <div class="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
                    <div
                        v-if="message?.emotion?.personality_trait"
                        class="personality-trait w-full sm:w-auto border border-ui-lines rounded-lg py-2.5 px-3"
                    >
                        <div class="uppercase text-xs text-placeholders font-semibold mb-2">
                            {{ t('callHistoryView.personality') }}
                        </div>
                        <div
                            class="font-medium"
                            style="color: #0D4AB4"
                        >
                            {{ getTranslate(`callHistoryView.ai.personalityTrait.${message.emotion.personality_trait?.toLowerCase()}`, message.emotion.personality_trait) }}
                        </div>
                    </div>
                    <div
                        v-if="message.emotion?.emotion"
                        class="w-full sm:w-auto border border-ui-lines rounded-lg py-2.5 px-3"
                    >
                        <div class="uppercase text-xs text-placeholders font-semibold mb-2">
                            {{ t('callHistoryView.currentEmotion') }}
                        </div>
                        <div
                            class="text-2xl font-medium flex items-center flex-wrap xl:flex-nowrap gap-3 capitalize"
                            :style="{color: emotionColor(message.emotion.emotion) || ''}"
                        >
                            <div class="flex-center flex-shrink-0">
                                <EmojiSvgIcon
                                    :key="message.emotion.emotion || 'emotion'"
                                    :name="message.emotion.emotion"
                                    size="2.25rem"
                                />
                            </div>
                            {{ getTranslate(`callHistoryView.ai.emotions.${message.emotion.emotion?.toLowerCase()}`, message.emotion.emotion) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VcCard>
</template>

<script setup lang="ts">
import { TMessageWithEmotionItem } from '@/types/types'
import { computed } from 'vue'
import { getCallerDataByMessage, getEmotionColor } from '@/composables/useAiTabData.ts'
import BaseEmojiGifIcon from '@/components/Icons/BaseEmojiGifIcon.vue'
import EmojiSvgIcon from '@/components/Icons/EmojiSvgIcon.vue'
import { getTranslateWithDefault } from '@/helpers/translate.ts'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
    message?: TMessageWithEmotionItem
}>()

const speakerName = computed(() => {
    return getCallerDataByMessage(props.message)
})

function getTranslate (str: string, defaultVal: string) {
    return getTranslateWithDefault(str, defaultVal)
}
function emotionColor (emotion: string = '') {
    return getEmotionColor(emotion)
}
</script>

<style lang="scss">
.chart-activity-card {
    @apply p-3 xl:p-5 rounded-xl min-h-[132px] flex items-stretch;
    .vc-card-content {
        @apply h-auto;
    }
    &__content {
        @apply grid gap-4 grid-cols-1 sm:grid-cols-[auto_1fr_auto] h-full;
    }

    &__message {
        @apply h-full flex flex-col justify-center;
    }

    &__activities {
        @apply flex items-start;
    }
    .personality-trait {
        @apply text-2xl max-w-full sm:max-w-72;
    }
}
</style>
