<template>
    <div class="recording-logs-tab">
        RecordingLogsTab
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
