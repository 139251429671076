<template>
    <VcCollapseItem
        ref="collapseRef"
        class="collapse-block"
        toggle-by-action
        is-open
    >
        <template #title="{ open }">
            <div class="block-title flex flex-grow flex-col gap-2 md:flex-row w-full md:w-auto justify-between md:items-center">
                <div>
                    {{ title }}
                </div>
                <slot
                    name="add-actions"
                    :open="open"
                />
            </div>
        </template>
        <template #icon="{ open }">
            <div class="collapse-block__action">
                <i
                    :class="open ? 'vc-lc-minimize-2' : 'vc-lc-maximize-2'"
                    class="transition-all duration-200"
                />
                {{ open ? t('callHistoryView.minimize') : t('callHistoryView.maximize') }}
            </div>
        </template>
        <template #default="{open}">
            <slot :open="open" />
        </template>
    </VcCollapseItem>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps<{
    title?: string
    content?: string
}>()

const { t } = useI18n()
</script>

<style lang="scss">
.collapse-block {
    @apply block border border-ui-lines rounded-lg px-3 lg:px-5;

    .vc-collapse-item__header {
        @apply items-end md:items-center;
    }
    &__title {
        @apply flex justify-between items-start;
    }

    &__action {
        @apply flex items-center gap-1 border border-ui-lines hover:border-secondary-actions--focus text-xs uppercase font-semibold rounded-[100px] px-2 py-1 h-auto cursor-pointer;
        transition: var(--base-transition);
    }
}
</style>
